.catalog-product-view {

  .restricted-states-notice {
    background-color: rgba($yellow, .33);
    font-size: rem-calc(14);
    margin: $global-margin 0;
    padding: $global-padding;

    &.hidden {
      display: none;
    }

    .restricted-states-list {
      display: block;
      font-weight: 500;
    }
  }
}
