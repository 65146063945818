
table {
  border: 0;
  border-collapse: collapse !important;

  > * {
    border: 0;
  }

  thead {
    background: $white;

    tr {

      th.col {
        border-bottom: 1px solid $grey-light;
      }
    }
  }

  tr {

    &:nth-child(2n) {
      background-color: $white;
    }

    &.item-actions {
      border-bottom: 1px solid $grey-light;
    }
  }

  &.cart {

    > .table-caption {
      display: none;
    }
    td{
      &.qty{
        label{
          display: block;
          > span{
            display: none;
          }
        }
      }
    }
  }

  // Cart sidebar, hide shipping selection value - shows shipping line item and cost.
  // That is the same class, used for the same information, within 2 separate tables in the cart - silly Magento.
  &.table-totals,
  &.table.totals {

    .totals.shipping {

      .value {
        font-size: 0;
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}
