// CMS Medicaid Page
.cms-page-view.cms-medicaid,
.medicaid-index-index,
.medicaid-aob-index {

  .block.free-shipping,
  .block.footer-callouts,
  .breadcrumbs {
    display: none;
  }

  .nav-toggle {
    display: none !important;
  }

  // Small screen changes.
  @include breakpoint(medium down) {

    .logo {
      padding-bottom: $global-padding;
    }

    .block.header-promo {
      right: $global-padding;
    }
  }

  .button {
    white-space: normal !important;
  }
}
