//Customer Account Dashboard
.customer-account-index {

  [class*="dashboard"] {
    background-color: $white;
    clear: both;

    .block-title {
      margin-top: 0;
      margin-left: 0;
      background-color: scale-color($secondary-color, $alpha: -80%);
    }

    .block-content {
      padding: $global-padding;
      margin-bottom: $global-margin * 2;
    }
  }
}

.account:not(.wishlist-index-index) {

  .actions-toolbar {

    .primary,
    .secondary {
      float: right;

      @include breakpoint(medium down) {
        margin-bottom: $global-margin / 2;
        margin-left: $global-margin / 2;
      }
    }

    .secondary {

      @include breakpoint(medium) {
        margin-right: $global-margin * 2;
      }
    }
  }
}

.customer-account-create {

  .actions-toolbar {

    .secondary {
      margin-top: $global-margin;

      @include breakpoint(medium) {
        margin-top: 0;
        margin-right: 0 !important;
      }
    }
  }
}
