
.form.medicaid-eligibility-form {
  > .fieldset {
    background: transparent;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    .field {
      flex: 1 1 100%;
      @include breakpoint(small only) {
        max-width: rem-calc(225);
      }
      .label {
        color: $white;
        font-weight: bold;
      }
    }
    .field-row {
      display: flex;
      width: 100%;
      @include breakpoint(small only) {
        flex-wrap: wrap;
        max-width: rem-calc(225);
      }
      .field.first_name {
        @include breakpoint(medium) {
          padding-right: $global-padding / 2;
        }
      }
      .field.last_name {
        @include breakpoint(medium) {
          padding-left: $global-padding / 2;
        }
      }
    }
    .field.city {
      @include breakpoint(medium) {
        flex-basis: 45%;
      }
    }
    .field.state,
    .field.zip_code {
      @include breakpoint(medium) {
        flex-basis: 20%;
        padding-left: $global-padding;
      }
    }
    .field.state {
      select {
        min-height: auto;
      }
    }
    .field.birth_date {
      .note {
        color: $white;
        margin-bottom: $global-margin / 2;
      }
    }
    .field.recaptcha {
      .g-recaptcha {
        @include breakpoint(medium down) {
          transform: scale(0.8);
          margin-left: $global-margin * -2.5;
        }
      }
    }
  }
  .actions-toolbar {
    .button {
      display: block;
      width: 100%;
    }
  }
}
