.page-header {
  background-color: $primary-color;
  position: relative;

  > .header.content {
    // Mobile Nav Toggle
    .action.nav-toggle {
      position: relative;
      display: flex;
      display: block;

      @include breakpoint(large) {
        display: none;
      }

      &:before {
        content: '\f0c9';
        font-family: fontawesome;
        font-size: 17px;
        line-height: 32px;
        border-radius: 35px;
        height: 35px;
        width: 35px;
        z-index: 10;
        position: absolute;
        top: -15px;
        left: 10px;
        border: 2px solid #fff;
        background: #51abff;
        color: #fff;
        text-indent: 8px;

        @media only screen and (min-width: 776px) and (max-width: 1152px){
            height: 50px;
            width: 50px;
            top: 20px;
            line-height: 46px;
            font-size: 35px;
        }

        @media only screen and (max-width: 320px) and (min-width: 0) {
          font-size: 12px;
          height: 30px;
          width: 30px;
        }
      }

      .nav-open & {
        &:before {
          background-image: url('../images/nav-toggle-close.svg');
        }

        &::after {
          background: rgba($blue-dark, 0.7);
          content: '';
          display: block;
          height: 100%;
          position: fixed;
          right: 0;
          top: 0;
          width: 100%;
          z-index: 5;
        }
      }
    }
  }
}

@media screen and (min-width: 1153px) {
  .nav-sections {
    .nav-sections-items {
      .navigation {
        .opener {
          display: none !important;
        }
      }
    }
  }
}
