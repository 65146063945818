// CMS Block/Widget to include category list.
.block.catalog-category {
  // @include grid-row;
  column-count: 2;
  margin-bottom: $global-margin * 2;

  @include breakpoint(medium) {
    column-count: 4;
  }

  >.category {
    display: block;
    margin: ($global-margin / 2) auto;
  }
}
