.box-tocart {

  .control {

    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button {  

      opacity: 0;
    }

    button {
      float: left;
      margin-top: 0;
      border-radius: 0;
      position: relative;
      z-index: 10;
    }

    input {
      float: left;
      width: 50%;
      height: $global-padding * 2.2;
      border-radius: 0;
      text-align: center;
    }
  }

  .actions {
    clear: both;
  }
}
