// Careers open positions listings.
.block.open-positions {

  > .inner {
    @include grid-row;

    > * {
      @include grid-column(12);
    }
  }

  .current-job-postings {
    @include grid-row;

    > * {
      @include grid-column(12);
    }
  }
}
