// Medicaid page locations map and list blocks
.block.medicaid-locations {
  @include grid-row;
  @include component-margin;
  max-width: $grid-row-narrow;

  > .block {
    @include grid-column(12);
  }

  // Locations map and text
  .block.medicaid-locations-map {
    // @include grid-row;
    margin-bottom: $global-margin;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  
    @include breakpoint(medium) {
      flex-direction: row;
    }

    > :first-child {

      @include breakpoint(medium) {
        flex: 1 0 65%;
      }
    }
  }

  // Locations listing.
  .block.medicaid-locations-list {

    ul {
      column-count: 2;
      column-gap: 20px;
      list-style: none;

      @include breakpoint(medium) {
        column-count: 4;
      }

      li {
        position: relative;

        // changing our list items from a bulleted list to a dash
        &:before {
          content: '-';
          margin-right: $global-margin / 4;
          font-weight: bold;
          font-size: $global-font-size * 1.2;
          color: $blue-light;
        }
      }
    }
  }
}
