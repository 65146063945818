// Medicaid fees table block.
.block.medicaid-fees {
  clear: both;
  max-height: 0;
  visibility: hidden;
  opacity: 0;
  transition: visibility 500ms ease, opacity 200ms ease, max-height 500ms ease;

  // Toggled open to show table.
  &.open {
    height: auto;
    max-height: 100%;
    visibility: visible;
    opacity: 1;
    margin-top: $global-margin;

    @include breakpoint(medium) {
      margin-top: $global-margin * 2;
    }
  }

  table {

    // We are hiding the table header on mobile.
    thead {

      > tr {

        > th {
          @include breakpoint(small only) {
            display: none;
          }
        }
      }
    }

    tbody {
      background-color: darken($white, 5%);

      tr {

        td {

          @include breakpoint(small only) {
            display: block;
            padding: ($global-padding / 2) ($global-padding);

            &::before {
              padding-right: $global-padding / 3;
              content: attr(data-th);
              display: inline-block;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
