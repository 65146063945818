.loading-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  padding-top: $global-padding * 4;
  text-align: center;
  background-color: rgba($white, 0.75);
  z-index: 100;

  .loader {
    background-color: rgba($white, 0.75);
    height: 100vh;

    p {
      font-weight: bold;
      font-size: $global-font-size * 1.5;
      margin-top: $global-margin * -2;
    }
  }
}

.tv-offer {
  background-color: #00519b !important; 
  color: white !important;
}

.tv-offer:before {
  content: none !important;
}

.order-success-block {
  margin-top: 40px;
}

// Page animation for mobile nav
.page-wrapper {
  transition: left 400ms ease;

  .nav-before-open & {
    position: relative;
    overflow: hidden;
    height: 100%;
    transition: left 400ms ease;
  }

  // .nav-open & {
  //   left: 80%;
  // }
}

// 2 Column layout changes - primarily for search and filters
.page-layout-2columns-left {
  &.catalogsearch-result-index,
  &.page-with-filter {
    .page-main {
      > .main {
        @include breakpoint(medium) {
          @include grid-column(8);
          @include grid-column-position(4);
        }
      }

      > .sidebar {
        @include breakpoint(medium) {
          @include grid-column(4);
          @include grid-column-position(-8);
          margin-top: $global-margin * 2;
        }
      }
    }
  }

  // Product page width overrides
  &.page-products {
    .page-main {
      // max-width: $grid-row-width;

      // For tablet and smaller we want the layered navigation to come before the products.
      @include breakpoint(small only) {
        display: flex;
        flex-direction: column;

        .main {
          order: 2;

          // First toolbar is hidden for tablet and down.
          .toolbar.toolbar-products {
            display: none;
          }

          .products.wrapper {
            // We had to hide both, so need to bring the second one back.
            + .toolbar.toolbar-products {
              display: block;
            }
          }
        }

        .sidebar {
          order: 1;
        }
      }
    }
  }
}

.block.myaccount {
  li {
    a {
      &::before {
        background: url('../images/sprite-1x.png') no-repeat -18px -23px;
        border-radius: 50px;
        height: 50px;
        width: 50px;
        display: inline-block;
        max-width: 50px;
        margin: 0 auto;
        border: 2px solid #fff;
        @include breakpoint(medium down) {
          background: url('../images/sprite-1x.png') no-repeat -6px -250px;
          border-radius: 35px;
          height: 35px;
          width: 35px;
        }
        @media only screen and (max-width: 320px) and (min-width: 0) {
          background: url('../images/sprite-1x.png') no-repeat -10px -250px;
          font-size: 12px;
          height: 30px;
          width: 30px;
        }
        @media only screen and (min-width: 776px) and (max-width: 1152px) {
          background: url('../images/sprite-1x.png') no-repeat -18px -23px;
          height: 50px;
          width: 50px;
          line-height: 46px;
          font-size: 35px;
        }
      }
    }
  }
}
.minicart-wrapper {
  .action.showcart {
    &::before {
      content: '\f07a' !important;
      color: white;
      display: inline-block;
      filter: drop-shadow(0 63px 122px rgba(2, 46, 88, 0.8));
      font-family: fontawesome;
      line-height: 48px;
      font-size: 25px;
      text-align: center;
      text-indent: -3px;
      background: #51abff;
      border-radius: 50px;
      height: 50px;
      width: 50px;
      display: inline-block;
      border: 2px solid #fff;
      @include breakpoint(medium down) {
        border-radius: 35px;
        height: 35px;
        width: 35px;
        font-size: 22px;
        line-height: 33px;
      }
      @media only screen and (max-width: 320px) and (min-width: 0) {
        font-size: 15px;
        height: 30px;
        width: 30px;
      }
      @media only screen and (min-width: 776px) and (max-width: 1152px) {
        height: 50px;
        width: 50px;
        line-height: 46px;
        font-size: 35px;
      }
    }
  }
}

.block.free-shipping {
  a {
    @include breakpoint(medium) {
      &:before {
        background: url(../images/sprite-1x.png) no-repeat -21px -92px;
        width: 28px;
        height: 35px;
      }
    }
  }
}

/*Css for crosssell products*/
.checkout-cart-index .products-crosssell {
  .owl-theme .owl-controls .owl-nav .owl-prev {
    @include breakpoint(large) {
      left: -50px !important;
    }
  }
  .owl-theme .owl-controls .owl-nav .owl-next {
    @include breakpoint(large) {
      right: -50px !important;
    }
  }
  .owl-carousel .owl-item img {
    width: 100%;
    @include breakpoint(medium-sm) {
      width: auto;
      min-height: 140px;
    }
  }
}

.disclaimer-content {
  max-width: 71rem;
  margin: 0 auto;
  padding: 0 0 10px;
  box-sizing: border-box;
  font-weight: 500;
  font-style: italic;
  p {
    color: #fff;
    font-size: 12px;
  }
  @media only screen and (max-width: 1024px) {
    margin: 0 1.875rem;
  }
  @media only screen and (max-width: 800px) {
    margin: 0 1.25rem;
  }
}
/*paybill page css*/

.paybill {
  margin: 20px 0;
  .field {
    width: 48%;
    display: inline-block;
    box-sizing: border-box;
  }
  .zip_code {
    text-align: right;
    @media screen and (max-width: 767px) {
      text-align: left;
    }
  }
  .field {
    label,
    .control {
      display: inline-block;
      vertical-align: top;
      .mage-error {
        margin-bottom: 10px;
        text-align: left;
      }
    }
  }
  .actions-toolbar {
    text-align: center;
  }
}
.error-text {
  padding: 10px 0 20px 0;
  text-align: center;
  p {
    color: inherit;
  }
}
.billpay_faq {
  list-style: none;
  padding: 0 2.5rem;
  margin: 0;
  @media screen and (max-width: 767px) {
    padding: 0 1rem;
  }
  .inner {
    background: #ccc;
    padding-left: 1em;
    overflow: hidden;
    display: none;
  }
  li {
    margin: 0.5em 0;
    list-style-type: decimal;
    font-weight: 500;
    color: #022e58;
    a.toggle {
      width: 100%;
      display: block;
      border-radius: 0.15em;
      transition: background 0.3s ease;
      color: inherit;
    }
  }
}
/*Paybill changes*/
.table-billpay-invoices {
  .title {
    label,
    span {
      display: inline-block;
      font-weight: 500;
      font-size: 1.2rem;
      color: #128ced;
    }
  }
  .review-title {
    font-weight: 500;
    font-size: 1.2rem;
    color: #128ced;
  }
}
.invoice-date {
  width: 50%;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
}
.guest-billpay-invoices {
  .field {
    label,
    span,
    .control {
      display: inline-block;
      vertical-align: top;
    }
    label {
      width: 24%;
      padding-left: 10px;
      @media screen and (max-width: 767px) {
        padding-left: 0;
        width: 100%;
      }
    }
    .control {
      width: 30%;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }
}

.credit-card {
  .note {
    color: rgba(198, 15, 19, 0.6);
  }
  .pay-invoice-label {
    h5 {
      width: auto;
      font-weight: 500;
      font-size: 1.2rem;
      color: #128ced;
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
      margin-bottom: 0;
    }
    span {
      vertical-align: middle;
    }
  }
  .title-strip {
    display: inline-block;
    font-weight: 500;
    font-size: 1.2rem;
    color: #128ced;
    background: #edebeb;
    width: 100%;
    padding: 5px 10px;
    margin: 10px 0;
  }
}
.fieldset.card-address,
.ccard.payment {
  margin: 0;
  padding: 0;
}
.exp_div {
  .month,
  .year {
    display: inline-block;
    .control {
      width: auto;
    }
  }
}
.cc-info {
  margin: 0;
  padding: 0;
  .legend {
    margin: 0;
    padding: 0;
    span {
      margin-top: 0;
    }
  }
}

.control.invoice-input {
  vertical-align: middle;
  input,
  strong {
    display: inline-block;
    width: auto;
  }
  input {
    width: 50%;
    @media screen and (max-width: 767px) {
      width: 95.5%;
    }
  }
}

.paybill-guest-view {
  .actions-toolbar {
    margin: 20px 0 0;
    .control {
      width: 54%;
      text-align: right;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }
  .block.footer-bottom {
    overflow: hidden;
  }
  .ui-tooltip {
    text-align: center;
  }
}

.block.block-new-customer {
  padding: 0 2rem;
  @media screen and (max-width: 768px) {
    padding: 0 3.2rem;
  }
}
.invoice-details {
  width: 60%;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
  td {
    vertical-align: top;
    word-break: break-all;
  }
}

.guest-billpay-invoices.payment_date {
  .label,
  .control {
    @media screen and (max-width: 767px) {
      width: auto;
    }
  }
}

.table-billpay-invoices {
  .actions-toolbar {
    width: 60.5%;
    float: right;
    @media screen and (max-width: 1024px) {
      width: 63%;
    }
    @media screen and (max-width: 768px) {
      width: 70%;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      text-align: right;
    }
  }
}

.block.block-new-customer {
  @media screen and (max-width: 480px) {
    padding: 0 1.2rem;
  }
  .online {
    margin-left: 2rem;
    @media screen and (max-width: 360px) {
      font-size: 15px;
    }
  }
}

.paybill-guest-index .page-main .note {
  color: #99a4ab;
}
