body {

  &.unsalable {

    .product-unsalable {
      background: $grey-lighter;
      border: 1px solid $grey-light;
      display: block;
      font-weight: bold;
      margin: $global-margin;
      padding: $global-padding;
    }

    .case-callout,
    .subscription-price,
    .subscription-wrapper,
    &.catalog-product-view .page-main .product-add-form .product-options-wrapper,
    &.catalog-product-view .page-main .product-add-form .product-options-bottom {
      display: none;
    }
  }
}
