//Customer login page
.login-container {

  > div {

    @include breakpoint(medium) {
      padding-left: 0;
    }

    .actions-toolbar {

      .primary a {
        @include button($reverse: true);
      }

      .secondary a {
        @include button($alt: true) {
          font-weight: 400;
        };
        @include breakpoint(medium) {
          white-space: normal!important;
        }
      }
    }
  }
}

// Customer account login form block
.customer-account-login {

  .login-container {

    fieldset {
      @include breakpoint(small only) {
        padding-left: 0;
        padding-right: 0;

        > div {
          padding-left: $global-padding;
          padding-right: $global-padding;
        }
      }

      .note {
        font-size: $global-font-size;
      }
    }
  }
}

// Forgot password screen
.form.password.forget {

  .actions-toolbar {

    .action.back {
      @include button($outline: true);
    }
  }
}

// Login form on checkout - continue as guest or enter password
.form.form-login {

  .hidden-login {
    @include grid-row;
    display: flex;
    height: auto;
    max-height: rem-calc(900);
    visibility: visible;
    opacity: 1;
    transition: visibility 500ms ease, opacity 200ms ease, max-height 500ms ease;

    > * {
      @include grid-column(12);
      margin-top: $global-margin / 2;
      padding-top: 0;

      @include breakpoint(medium) {
        @include grid-column(6);
      }

      h3 {
        font-size: rem-calc(20);
      }
    }

    .guest-checkout {

      > a {
        // @include button-base;
        @include button($outline: true);
      }
    }

    .actions-toolbar {

      .secondary {
        margin: 0;

        .remind {
          padding-right: 0;
        }
      }
    }

    &.hidden {
      max-height: 0;
      visibility: hidden;
      opacity: 0;
    }
  }
}
@media screen and (max-width: 768px) {
  body {
    .login-container > div .actions-toolbar .secondary a {
      white-space: normal !important;
    }
  }
}

@media screen and (max-width: 767px) {
  body._has-modal .modal-popup._show .modal-inner-wrap,
  body._has-modal-custom .modal-custom._show .modal-inner-wrap {
    max-height: none!important;
    top: 1rem!important;
    .modal-content {
      max-height: 100px!important;
      overflow-y: auto!important;
    }
  }
}

@media screen and (max-width: 480px) {
  body._has-modal .modal-popup._show .modal-inner-wrap,
  body._has-modal-custom .modal-custom._show .modal-inner-wrap {
    .modal-content {
      max-height: 390px!important;
      overflow-y: auto!important;
    }
  }
}

body.catalog-product-view .page-main .product-add-form .fieldset input {
  height: 3rem;
  border-radius: 4px;
}