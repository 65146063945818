.customer-subscriptions-index {

  .orders-history {
    table.history.table-order-items tbody {
      > tr:nth-child(2n) {
        background-color: whitesmoke;
      }

      .col.description {

        .warning {
          background-color: #ffe99c;
          color: $black;
          font-weight: 400;
          font-size: rem-calc(13);
          margin: $global-margin 0;
          padding: $global-padding * 1;

          a {
            font-weight: 600;
          }
        }
      }

      .col.actions {
        .action {
          font-size: rem-calc(13);
        }
      }
    }
  }
}

.subscription {

  &.table-wrapper {

    .input-text.qty {
      border: 0;
    }

    .actions-toolbar {
      display: none;
    }

    .product-name {

      .product-sku {
        display: block;
        font-weight: 600;
      }
    }
  }
}

.block-subscription-info {

  .box {
    margin-bottom: 30px;
  }

  .item-options {

    dt {
      clear: left;
      display: inline-block;
      float: left;
      width: 40%;
    }
  }
}

.subscription-actions-toolbar {
  float: right;

  .action.primary {
    float: right;
  }

  .action.secondary {
    padding: 7px 15px 7px 0;
    vertical-align: middle;
  }
}
