.account {

  .page-main {

    .main {

      @include breakpoint(medium) {
        padding: 0 $column-gutter !important;
      }
    }
  }
}

.customer-account-forgotpassword {

  .page-main {

    .main {
      @include content-margin-gap-bottom;
    }
  }
}

body {
  &.account {
    .toolbar-products {
      .limiter,
      .pages {
        display: block;
        margin: 5px 15px 0 0;
      }
      .toolbar-sorter.sorter {
        display: table!important;
        .sorter-label {
          padding-right: 5px;
        }
        .sorter-action {
          padding-left: 5px;
        }
      }
      .field.limiter {
        margin-left: 15px;
        margin-right: 0;
        label {
          padding-right: 0;
        }
        .limiter-text {
          padding-left: 5px;
        }
      }
      .toolbar-amount {
        position: relative;
        top:10px!important;
      }
    }

    &.customer-billpay-index,
    &.customer-billpay-success,
    &.customer-billpay-pay,
    &.customer-billpay-view {
      .billpay-invoices {
        .table-caption {
          display: none;
        }
        th,
        td {
          border:1px solid lightgray;
        }
        .col.actions {
          text-align: center;
          &:before {
            display: none!important;
          }
        }
        .action.view {
          background: #128ced;
          color: #fff;
          font-weight: bold;
          padding:10px;
          border-radius: 3px;
          box-shadow:none;
          font-size:14px;
          &:hover {
            background: #022e58;
            box-shadow: none;
          }
        }

        tfoot {
          tr {
            th, td {
              text-align: right;
            }
          }
        }
      }
      .table-billpay-invoices {
        .partial-amount {
          border: 1px solid #c6c6c6;
          height: 44px;
          line-height: 44px;
          padding:5px;
          box-sizing: border-box;
        }
      }
    }

    .billpay-invoices {
      .box {
        margin: 0 -10px;

        .block-title {
          margin: 0;
          padding: 0 10px;
          h2 {
            font-size: 14px;
          }
        }
        .fieldset {
          padding:0 10px;
          float: left;
          width:33%;
          box-sizing: border-box;
          margin:0x;
          .legend {
            margin:0;
            padding:0;
            span {
              display: block;
              font-weight: 600;
              margin: 0;
            }
          }
          &.payment {
            .field {
              &.date {
                .fields {
                  .field {
                    &.month {
                      float: left;
                      width: 60%;
                    }
                    &.year {
                      float: right;
                      width: 35%;
                    }
                  }
                  &:after {
                    clear: both;
                    content: '';
                    display: block;
                    overflow: hidden;
                  }
                }
              }
            }
          }
        }
        .select, select {
          min-height: 2rem;
        }
      }
      &:after {
        clear: both;
        content: '';
        display: block;
        overflow: hidden;
      }
    }

    .invoice-details {
      dt {
        float: left;
        clear: both;
        margin: 0 10px 0 0;
      }
      dd {
        float: left;
        margin-bottom: 10px;
        span {
          position: relative;
          top:-7px;
        }
      }
      &:after {
        clear: both;
        content: '';
        display: block;
        overflow: hidden;
      }
    }
    .invoice-date {
      border-bottom: 1px solid lightgray;
      display: table;
      padding: 0 0 10px;
      margin-bottom: 10px;
      width: 100%;
      div {
        display: table-cell;
        width: 50%;
        label {
          font-weight: 700;
          display: table-cell;
          &:after {
            content: ':';
            margin-right: 5px;
          }
        }
        span {
          font-weight: 500;
          display: table-cell;
        }
      }
    }

    .main {
      .messages {
        .message {
          padding:5px 10px;
          margin-bottom: 15px;
          &:before {
            font-family: 'fontawesome';
            content: '';
            font-size:20px;
            display:table-cell;
            padding-right:10px;
            vertical-align: middle;
          }
          &.notice {
            background: #fdf0d5;
            color: #7a4c1f;
            &:before {
              content: '\f071';
              color: #c07600;
            }
          }
          &.success {
            background: #e5efe5;
            color: #247625;
            &:before {
              content: '\f058';
              color: #006400;
            }
          }
          p {
            display: table-cell;
            vertical-align: middle;
          }
        }
      }
    }
    .search-invoice-amount {
      .fieldset {
        padding: 0;
        .field {
          .input-box {
            float: left;
            width: 25%;
          }
          .control {
            float: left;
            width: 25%;
            padding: 0 0 0 25px;
            .input-text {
              height: 48px;
            }
          }
        }
        .actions-toolbar {
          float: right;
          width: 50%;
          .primary {
            float:left;
            margin: 0 0 0 25px;
          }
          .secondary {
            float: right;
            margin:0!important;
          }
        }
        &:after {
          clear: both;
          content: '';
          display: block;
          overflow: hidden;
        }
      }
    }
  }
}
@media only screen and (min-width: 768px) {
  body.account.customer-billpay-index .billpay-invoices .col.price,
  body.account.customer-billpay-success .billpay-invoices .col.price,
  body.account.customer-billpay-pay .billpay-invoices .col.price,
  body.account.customer-billpay-view .billpay-invoices .col.price {
    text-align: right;
  }
}
@media only screen and (max-width: 760px) {

  body {
    &.account {
      .toolbar-products {
        .limiter,
        .pages {
          display: block;
          margin: 5px 15px 0 0;
        }
        .toolbar-sorter.sorter {
          display: table!important;
          .sorter-label {
            padding-right: 5px;
          }
          .sorter-action {
            padding-left: 5px;
          }
        }
        .field.limiter {
          margin: 15px auto 0;
          text-align: center;
          float: none;
        }
        .toolbar-amount {
          position: relative;
          top:10px!important;
          left: 10px;
        }
        .toolbar-sorter {
          text-align: center;
          .sorter-label {
            text-align: right;
          }
          .sorter-action {
            text-align: left;
          }
        }
      }

      &.customer-billpay-index,
      &.customer-billpay-success,
      &.customer-billpay-pay,
      &.customer-billpay-view {
        .billpay-invoices {
          display: block;
          .table-billpay-invoices {
            margin-bottom: 0;
          }
          thead {
            display: none;
          }
          tbody, tfoot, tr {
            display: block;
            width: 100%;
          }
          tbody {
            td {
              &:before {
                min-width: 120px;
              }
            }
          }
          tfoot {
            tr {
              display: table;
              width: 100%;
              th, td {
                display: table-cell;
                width: 50%;
                text-align: right;
              }
            }
          }
          border-bottom: 1px solid lightgray;
          .table-caption {
            display: none;
          }
          th,
          td {
            border:1px solid lightgray;
            border-bottom: none;
          }
          .col.actions {
            text-align: center;
            &:before {
              display: none!important;
            }
          }
          .action.view {
            display: block;
            box-shadow: none;
          }
        }
        .invoice-success {
          th {
            display: none!important;
          }
        }
      }
      &.customer-billpay-success {
        .billpay-invoices {
          tbody {
            td {
              &:before {
                min-width: 140px;
              }
            }
          }
        }
      }

      .billpay-invoices {
        .box {
          margin:0;
          padding:0;

          .block-title {
            margin: 0;
            padding:0;
          }
          .fieldset {
            padding:0;
            float: none;
            width:100%;
            box-sizing: border-box;
            margin:0 0 20px;
          }
        }
        margin-bottom: 25px;
      }
      .invoice-details {
        dd {
          span {
            top:0;
          }
          .partial-amount {
            display: block;
            margin-top: 10px;
          }
        }
      }

      .invoice-date {
        display: block;
        div {
          display:block;
          width:100%;
          label {
            font-weight: 700;
            display:inline-block;
            margin-right: 10px;
          }
          span {
            font-weight: 500;
            display:inline-block;
          }
        }
      }

      .search-invoice-amount {
        .fieldset {
          padding: 0;
          .field {
            .input-box {
              float: none;
              width:100%;
            }
            .control {
              float:none;
              width:100%;
              padding:0;
            }
          }
          .actions-toolbar {
            float: none;
            width: 100%;
            .primary {
              float:left;
              margin:0;
            }
            .secondary {
              float: right;
              margin: 0;
            }
            &:after {
              clear: both;
              content: '';
              display: block;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
}