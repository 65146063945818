
.page-header {
    
  > .header.content {

    > .block.block-search {
      position: relative;

      input,
      input:focus {
        border-radius: $global-radius 0 0 $global-radius;
        padding-left: $global-padding;
        font-size: $global-font-size * 0.85;
        border: none;
        background: $white;
        height: $global-padding * 3;
        color: rgba($blue-dark, 0.5);
        box-shadow: 0 0 0 rgba(0, 0, 0, 0.0);
        transition: box-shadow 400ms ease;

        &::placeholder {
          color: rgba($blue-dark, 0.5);
        }
      }
    
      input:focus {
        box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
        color: rgba($blue-dark, 0.9);
      }

      .form.minisearch {
        @include grid-row;

        > * {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }

        .field.search {
          @include grid-column(10);
        }

        .actions {
          @include grid-column(2);
          display: block;

          .action.search {
            @include icon($position: before, $icon-set: fontawesome, $icon: search);
            height: $global-padding * 3;
            margin-top: 0;
            border-radius: 0 $global-radius $global-radius 0;
            box-shadow: none;

            > span {
              display: none;
            }
          }
        }
      }
    }
  }
}

