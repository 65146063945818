// This is specifically for the quantity buttons for the add to cart form
// this is HDIS specific overrides from HDIS Default
.product-add-form {

  .box-tocart {

    // simple increase and decrease quantity buttons
    .value-button {
      padding: ($global-padding * 0.75) 0;
      height: $global-padding * 3;

      @include breakpoint(medium) {
        height: $global-padding * 1.5;
        line-height: 1.25;
        padding: inherit;
      }

      &.increase {
        border-radius: $global-radius;

        @include breakpoint(medium) {
          border-radius: 0 $global-radius 0 0;
        }
      }

      &.decrease {
        border-radius: $global-radius;

        @include breakpoint(medium) {
          border-radius: 0 0 $global-radius 0;
        }
      }
    }
    .mage-error{
      clear: both;
    }
  }
}
