//Individual product view page
.catalog-product-view {

  .product-info-main {

    @include breakpoint(medium) {
      @include grid-column(4);
      float: right;
    }

    .page-title-wrapper {
      padding-left: 0;
    }

    .product-add-form {

      label {

        color: $grey;
        padding-left: 0;
      }
    }
  }

  // Add customer review of product
  .review-add {

    legend {

      display: block;
      text-align: center;

      > span,
      > strong {

        display: block;
      }
    }
  }
}
