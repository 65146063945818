
// Video thumbnail icon - moved into Wampa as well, for some reason it's not being picked up right now - quick fix.
.video-thumb-icon {
  position: relative;

  &::after {
    background: url('../images/gallery-sprite.png') bottom right;
    bottom: 0;
    content: '';
    height: 100px;
    left: -5px;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 100px;
  }
}

