.formbuilder-form {
  padding-bottom: $global-padding * 2;

  @include breakpoint(medium) {
    padding-bottom: $global-padding * 4;
  }

  .form-content {

    .input-box {
      width: 100% !important;
    }

    .field {

      .col-sm-10 {
        width: 100% !important;
      }
    }
  }

  .formbuilder.form {
    @include breakpoint(small only) {
      margin-bottom: $global-margin * 2;
    }

    label {
      margin: 0;
      padding: 0;
      color: $primary-color;
      font-weight: bold;
      font-size: $global-font-size;
    }

    i {
      margin-right: $global-margin / 2;
    }

    input,
    textarea {
      margin-bottom: 0;
      border-radius: $global-radius;
      padding-left: $global-padding;
    }

    .help-block {
      margin: 0;
      margin-top: $global-margin / 2;
      font-size: $global-font-size * 0.9;
    }

    .field {
      margin-bottom: $global-margin;
    }

    .required {

      em {
        color: $secondary-color;
        font-size: $global-font-size * 1.5;
        vertical-align: middle;
      }
    }
  }
}
