
.block.medicaid-footer-callout {
  // clear: both;
  padding-top: $global-padding * 3;
  padding-bottom: $global-padding * 3;
  background-color: $blue-light;
  text-align: center;

  h3,
  strong {
    font-size: rem-calc(24);
    margin-bottom: 0;
  }

  p {
    display: block;
    margin-bottom: 0;
  }

  h3,
  p,
  a {
    color: $white;
  }

  a {
    transition: color 400ms ease;

    &:hover {
      color: $yellow-dark;
    }
  }
}
.btn-call-now{
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 99;
    max-width: 75%;
	@include breakpoint(medium) {
		max-width: 50%;
	}
	.close-callnow-btn {
		position: absolute;
		top: 0;
		right: 0;
		cursor: pointer;
		width: 30px;
		&:before{
			content: '✕';
			position: relative;
			left: 6px;
			font-weight: 600;
			font-size: 20px;
			top: 2px;
			color: $white;
		}
	}
}

.btn-call-now{
  @media only screen and (max-width: 767px) {
    a{
      padding-right: 20px;
      display: inline-block;
      box-shadow: -1px -2px 8px #555;
    }
    .desktop-image{
      display:none;
    }
    .close-callnow-btn{
      top: 0;
      right: 0;
      background: #63c800;
      width: 26px;
      height: 100%;
      &:before{
        font-size: 12px;
        left: 12px;
        top: -4px;
        cursor: pointer;
      }
    }
  }
  @media only screen and (min-width: 768px) {
    .mobile-image{
      display:none;
    }
  }
}
