body,
html {
  font-size: 100%;
  font-family: Arial, sans-serif, Helvetica, 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-smoothing: antialiased;
  color: $body-font-color;
}

// headings
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Arial, sans-serif, Helvetica, 'Open Sans';
  font-weight: 700;
  // line-height: 1;
  color: $blue-dark;
}

h1 {
  @include header-size(h1);
  margin-bottom: $global-margin;
}

h2 {
  @include header-size(h2);
  color: $primary-color;
}

h3 {
  @include header-size(h3);
  font-weight: 500 !important;
}

h4 {
  @include header-size(h4);
}

h5 {
  @include header-size(h5);
}

h3,
h4,
h3 a,
h4 a {
  font-weight: 700;
}

// link
a {
  transition: color 300ms, border 300ms, background-color 300ms, padding 300ms;
  font-weight: 500;
  color: $primary-color;
  border: 0;
  outline: none;

  &:hover {
    color: $secondary-color;
  }
}

a + a {
  border-left: 1px solid $grey;
  margin-left: $global-margin / 2;
  padding-left: $global-padding;
}

strong {
  font-weight: 700;
}

p:last-child {
  margin: 0;
}

dl,
ol,
p,
ul {
  color: $body-font-color;
  margin-bottom: rem-calc(10);

  @include breakpoint(medium) {
    margin-bottom: rem-calc(22);
  }
}

//inline labels
.field.label-inline {

  > * {
    display: inline;
  }
}

// text highlighting
::selection {
  color: $white;
  background: $primary-color;
}
