//Block Search from Header
.block-search {
  float: right;
  padding-left: 15px;
  position: relative;
  width: 130px;
  z-index: 4;

  @include breakpoint(medium) {
    width: 250px;
  }

  .block-title {
    font-size: 0;
  }

  input {
    border: 1px solid darken($grey, 5%);
    border-radius: $global-radius;
  }
}
