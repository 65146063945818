
.block.medicaid-products {
  background-color: $grey-lighter;

  > .inner {
    @include grid-row;
    @include component-padding;
    @include component-margin-top;
    max-width: $grid-row-narrow;

    > * {
      @include grid-column(12);
      text-align: center;
    }
  }

  ul {
    margin-top: $global-margin;
    display: flex;
    list-style-type: none;
    flex-direction: column;
    flex-wrap: wrap;

    @include breakpoint(medium) {
      flex-direction: row;
    }

    li {
      width: 100%;
      box-shadow: 0 1px 10px rgba($black, 0.15);
      background-color: $white;
      border-radius: $global-radius;
      padding: $global-padding * 2;
      margin: $global-margin 0;
      font-weight: $font-weight-bold;

      @include breakpoint(medium) {
        float: left;
        width: 45%;
        margin: $global-margin;
      }

      @include breakpoint(large) {
        width: 30%;
      }

      img {
        display: block;
        margin: 0 auto;
      }
    }
  }
}
