// Product grid for search results and product category pages
.catalogsearch-result-index,
.catalog-category-view {

  .product-grid,
  .products.grid {

    .product-item {
      padding: $global-padding !important;
      padding-top: 0;
      box-sizing: border-box;
      background-color: $white;
      text-align: center;
      position: relative;

      @include breakpoint(medium) {
        min-height: $global-padding * 28.5;
      }

      @include breakpoint(small only) {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0;
        min-height: $global-padding * 23;
      }

      .product.details {

        .product-attribute {
          color: $primary-color;
        }

        .price-final_price {

          span {
            font-weight: bold;
            color: $grey-dark;
          }
        }

        .product.actions {
          margin: $global-margin 0;

          @include breakpoint(medium) {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
          }

          > div {
            margin: 0 auto;
          }

          a {

            &::after {
              display: none;
            }
          }
        }
      }
    }
  }
}

// Related Products Grid
.grid.products-grid {
  &.products-related,
  &.products-upsell {
    margin-bottom: 0;
    padding-bottom: 0;

    .product-items {

      li {
        @include grid-column(12);
        min-height: $global-padding * 12;
        text-align: center;
        margin-bottom: $global-margin;

        @include breakpoint(medium) {
          @include grid-column(3);
          float: left !important;
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
      }
    }
  }
}

// Featured Products Grid
.grid.products-grid {

  .widget-product-grid {

    li {
      @include grid-column(6);

      @include breakpoint(medium) {
        @include grid-column(3);
        float: left !important;
        padding: inherit !important;
      }

      > div {
        width: 60%;
        margin: 0 auto;
        text-align: center;

        .special-price {

          .price-label {
            display: none;
          }
        }

        .old-price.no-display {
          display: none;
        }
      }
    }
  }
}

