.sidebar {

  .logout {

    li {

      a {
        background-color: $blue-light;
        color: $white;
        padding: $global-padding/2 $global-padding;
        text-align: center;

        &:before {
          color: $white;
        }

        &:hover {
          background-color: $blue-dark;
        }
      }
    }
  }
}
