// Page Headers

.page-wrapper {

  .page-header {
    margin-top: 0;

    .panel.wrapper {
      width: 100%;
      position: relative;

      @include breakpoint(medium) {
        border-bottom: 1px solid $grey-light;
        background-color: rgba($grey, 0.1);
      }

      .panel.header {
        @include grid-row;
      }
    }

    .header.content {
      @include grid-row();
      padding-top: $global-padding * 2;
      padding-bottom: $global-padding;

      .logo {
        position: relative;
        z-index: 5;
        padding-left: $global-padding;

        @include breakpoint(medium) {
          margin: ($global-margin / -2) auto ($global-margin * 2) 0;
          padding-left: $global-padding * 4;
        }

        img {
          margin-bottom: $global-margin * 2;
        }
      }
    }
}

  .panel.header {

    padding: 0;

    @include breakpoint(medium) {
      padding: $global-padding;
    }

    > .header.links {

      float: right;
      list-style: none;
      margin: 0;
      padding: 0;

      @include breakpoint(small only) {
        display: none;
      }

      li {
        display: inline-block;
        margin-left: $global-margin;
      }
    }
  }

  .action.skip:not(:focus) {

    font-size: 0;
    line-height: 0;
  }
}
