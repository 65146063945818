
.block.items-in-cart {

  .minicart-items {
    list-style: none;
    margin-left: $global-margin / 5;

    > .product-item {
      margin-bottom: $global-margin;

      &:not(:last-of-type) {
        border-bottom: 1px solid rgba($primary-color, 0.4);
        padding-bottom: $global-padding;
      }
    }

    .product:not(.options) {
      display: flex;

      .product-image-container {
        margin-right: $global-margin / 2;
      }

      .product-item-details {
        margin-top: 0;
        padding-top: 0;
      }
    }
  }
}
