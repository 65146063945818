
.product.details.product-item-details {

  .price-final_price {
    font-weight: bold;
  }

  .product-reviews-summary {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .rating-summary {
      margin-right: $global-margin / 2;
    }
  }

  .actions {
    display: flex;
    align-items: center;

    .actions-primary {
      margin-right: $global-margin / 2;
    }

    @include breakpoint(small only) {
      display: none;
    }
  }
}

// Product item details in cart and minicart
.cart.table-wrapper,
.minicart-items-wrapper {

  .product-item-details {
    margin-top: $global-margin / 2;
    padding-top: $global-padding;
  
    .product-item-name {
      font-size: $global-font-size * 1.25;
      display: block;
  
      + .item-options {
        margin-top: $global-margin / 4;
      }
    }
  
    dl {
  
      dt,
      dd {
        float: left;
      }
  
      dt {
        clear: left;
        margin-bottom: 0;
  
        &::after {
          content: ':';
          margin-right: $global-margin / 2;
          padding-left: $global-padding / 10;
        }
      }
    }
  }
}
