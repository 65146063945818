//Product Grid
.grid.products-grid {
  @include grid-row;
  margin-bottom: $global-margin * 2;
  padding-bottom: $global-padding;
  background-color: $white;
  box-sizing: border-box;

  @include breakpoint(medium) {
    padding-left: $global-padding;
    padding-right: $global-padding;
  }

  .product-items {
    list-style: none;
    margin-left: 0;

    li {
      @include grid-column(6);
      padding-left: 0 !important;
      padding-right: 0 !important;
      margin-bottom: $global-margin * 2;
      min-height: $global-padding * 21;

      @include breakpoint(medium) {
        @include grid-column(4);
        padding-left: $global-padding / 4 !important;
        padding-right: $global-padding / 4 !important;
        min-height: $global-padding * 25;
      }

      &:last-child:not(:first-child) {
        float: left;
      }
    }

    .product-item-photo {
      display: block;
    }
  }
}
