.swatch-attribute {

  .swatch-attribute-label {
    font-weight: bold;
  }

  .swatch-attribute-selected-option {
    padding-left: $global-padding;

    // &::before {
    //   content: ':';
    //   margin-left: $global-margin / 10;
    //   margin-right: $global-margin / 2;
    // }
  }

  .swatch-attribute-options {
    display: flex;
    flex-direction: row;
    margin-top: $global-margin / 2;
    flex-wrap: wrap;
  
    > div,
    > a {
      padding: 0 ($global-padding / 2);
    }
  
    .color {
      padding: ($global-padding / 10) ($global-padding / 5);
      min-width: rem-calc(30);
      max-width: rem-calc(90);
      height: rem-calc(20);
      float: left;
      margin: 0 ($global-margin / 2) ($global-margin / 4) 0;
      text-align: center;
      cursor: pointer;
      position: relative;
      border: 1px solid $grey-light;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .swatch-option {

      &.selected {
        border: 1px solid $secondary-color;
      }
    }
  }
}
