// CMS Pages with forms and content split 50/50 on desktop.
.cms-page-view {

  main {

    &.page-main {
      margin-top: 0;
    }
  }

  .ves-inner {

    .row-inner {
      @include grid-row;
      max-width: $grid-row-narrow;

      > .col-lg-6 {
        @include grid-column(12);

        @include breakpoint(medium) {
          @include grid-column(6);
        }
      }
    }
  }

  .formbuilder.form {

    .row {
      margin-left: 0;
      margin-right: 0;
    }

    input {
      padding-left: $global-padding / 2;
    }

    label {
      font-size: $global-font-size !important;
      line-height: $global-padding * 2;
    }

    .field {

      // Dropdown fields
      .response-field-dropdown {

        select {
          min-height: $global-padding * 2;
        }

        .input-box {
          height: 0;
        }
      }

      // Address fields
      .response-field-address {

        .city,
        .state {

          @include breakpoint(medium) {
            width: 48%;
          }
        }

        .city {
          margin-right: $global-margin;
        }
      }

      // Radio fields
      .response-field-radio {
        margin-top: $global-margin * 2;
      }

      // Section breaks
      .response-field-section_break {
        border-bottom: 1px solid $grey-light;
        margin-top: $global-margin;
      }
    }
  }

  &.cms-free-catalog-tv {

    .field {
      // Radio fields
      .response-field-radio {
        margin-top: 0;
      }
    }
  }
}
