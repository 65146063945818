
.account.customer-paymentinfo-index {

  .block.block-addresses-list {

    .addresses {

      > .fieldset {
        margin-top: 0;

        legend {
          padding-top: $global-padding * 2;
        }
      }
    }
  }

  // Add a CC headline
  .box {

    > .block-title {

      h2 {
        font-size: rem-calc(24);
        color: $secondary-color;
      }
    }
  }

  // Add a credit card form.
  form {
    display: block;

    .fieldset {
      @include grid-row;

      // customer information
      .field-name-firstname,
      .field-name-lastname,
      .company,
      .telephone {
        @include grid-column(12);
        padding-left: 0 !important;

        @include breakpoint(medium) {
          @include grid-column(6);
        }
      }

      // address information
      .street {
        @include grid-column(12);
        @include grid-row;
        padding-left: 0 !important;

        > .control {
          display: flex;
          flex-direction: column;

          @include breakpoint(medium) {
            flex-direction: row;
          }

          > input,
          .nested {
            flex: 1;

            @include breakpoint(medium) {
              width: 50%;
            }
          }

          .nested {

            @include breakpoint(medium) {
              margin-top: $global-margin * -1.55;
              padding-left: $global-padding;
            }
          }
        }
      }

      .city,
      .state,
      .region,
      .zip,
      .country {
        @include grid-column(12);
        padding-left: 0 !important;

        @include breakpoint(medium) {
          @include grid-column(6);
        }
      }

      // CC information
      .field.type,
      .field.number {
        @include grid-column(12);
        padding-left: 0 !important;

        @include breakpoint(medium) {
          @include grid-column(6);
        }
      }

      .field.date {
        @include grid-row;
        @include grid-column(12);
        padding-left: 0 !important;

        @include breakpoint(medium) {
          @include grid-column(8);
        }

        .field.month,
        .field.year {
          @include grid-column(12);
          padding-left: 0 !important;
          padding-right: 0 !important;

          @include breakpoint(medium) {
            @include grid-column(6);
            padding-left: 0 !important;
            padding-right: $global-padding * 1.875 !important;
          }
        }
      }

      .field.cvv {
        @include grid-column(12);
        padding-left: 0 !important;

        @include breakpoint(medium) {
          @include grid-column(4);
        }
      }
    }
  }
}
