.checkout-index-index {

  // Flexbox reorder of payment method - moves billing address before payment method inputs.
  .checkout-payment-method {

    .form.payments {

      > .fieldset {

        > .inner {
          display: flex;
          flex-direction: column;

          .opc-payment {
            order: 2;
          }

          .checkout-billing-address {
            order: 1;

            .billing-address-form {

              > form {
                padding: 0;

                > fieldset {

                  @include breakpoint(medium down) {
                    padding: 0;
                  }
                }
              }
            }
          }

          .opc-payment-additional {
            order: 3;
          }
        }
      }
    }
  }

  // Main checkout and cart wrapper
  .opc-wrapper {

    // yes this is an id don't yell at me
    #opc-new-shipping-address { // sass-lint:disable-line no-ids

      &.open {
        display: block !important;
      }
    }

    .payment-method {
      border-bottom: 1px solid $grey-light;
      padding-bottom: $global-padding;
      margin-bottom: $global-margin;

      &:not(._active) {

        > .payment-method-title {
          
          > .label {
            @include icon($position: after, $icon: more) {
              position: absolute;
              top: 0;
              right: 0;
              font-weight: bold;
            };
          }
        }

        > .payment-method-content {
          display: none;
        }
      }

      .payment-method-title {

        > .radio {
          display: none;
        }

        > .label {
          @include icon($position: after, $icon: less) {
            position: absolute;
            top: 0;
            right: 0;
            font-weight: bold;
          };
          display: block;
          position: relative;
          font-size: $global-font-size;
        }
      }

      ul {
        list-style: none;

        li {
          display: inline-block;
        }
      }

      .payment.items.ccard {

        br {
          display: none;
        }
      }

      .field.number.required {
        margin-top: $global-margin;
      }

      .credit-card-types {

        li {
          padding-top: 0;
        }
      }
    }
  }
}
