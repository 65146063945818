
// HDIS Homepage promo callouts
// 2 column image grid
.block.homepage-callouts {
  @include grid-row;
  margin-top: $global-margin * 2;
  margin-bottom: $global-margin * 2;

  .row {
    @include grid-column(12);

    .row-inner {
      @include grid-row;
      max-width: $grid-row-tiny;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      @include breakpoint(medium) {
        flex-direction: row;
        justify-content: center;
      }

      .column {
        @include grid-column;

        @include breakpoint(medium) {
          @include grid-column(6);
          padding-right: 0 !important;
        }

        .col-inner.stacked {

          @include breakpoint(medium) {
            display: flex;
            flex-direction: column;
            height: 100%;

            > div:first-of-type {
              flex: 1;
            }
          }
        }

        img {
          width: 100%;
        }
      }
    }
  }

  &.callouts6040 {

    .row {

      .row-inner {

        .column {

          &:first-of-type {

            @include breakpoint(medium) {
              @include grid-column(7);
              padding-right: 0 !important;
            }
          }

          &:last-of-type {

            @include breakpoint(medium) {
              @include grid-column(5);
            }
          }
        }
      }
    }
  }
}

// Title Block
.block.block-title {
  text-align: center;

  h2 {
    font-weight: 500;
    color: $blue-dark;
  }
}
