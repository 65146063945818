//Table styles - used in Magento 2
table {

  border: 1px solid $grey-light;
  text-align: left;
  display: table;
  border-collapse: separate;
  border-spacing: 2px;

  caption {

    text-align: left;
    padding-left: 0;
  }

  td {

    vertical-align: top;
  }
}

//Account Order table for small screens.
.table-wrapper .table:not(.totals):not(.cart):not(.table-comparison) {

  > tbody {

    > tr {

      td,
      th {
        @include breakpoint(small only) {
          display: block;
          padding: ($global-padding / 2) ($global-padding);
        }

        &::before {
          @include breakpoint(small only) {
            padding-right: $global-padding / 4;
            content: attr(data-th) ': ';
            display: inline-block;
            font-weight: bold;
          }
        }
      }

      > td.col.qty {
        @include breakpoint(small only) {
          text-align: left;
        }
      }
    }
  }

  > thead {

    > tr {

      > th {
        @include breakpoint(small only) {
          display: none;
        }
      }
    }
  }
}
