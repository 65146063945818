//Custom modal overlay overrides from HDIS Default
body {

  &._has-modal,
  &._has-modal-custom {

    .modal-popup._show,
    .modal-custom._show {

      .modal-header {

        .action-close {
          box-shadow: none;
        }
      }
    }
  }
}
