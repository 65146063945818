////////////////////////////////////////////////////////////////////////////////
// MIXINS
// -----------------------------------------------------------------------------

//////////////////
//button base styles
//////////////////
@mixin button-base {
  display: inline-block;
  font-size: rem-calc(16);
  white-space: nowrap;
  font-family: $body-font-family;
  font-weight: $font-weight-bold;
  line-height: $global-lineheight;
  text-align: center;
  text-transform: capitalize;
  background-color: $secondary-color;
  box-shadow: 0 10px 20px rgba($blue-shadow, 0.4);
  color: $white;
  padding: ($global-padding * 0.625) ($global-padding * 1.5625);
  margin: 0;
  cursor: pointer;
  border-radius: $button-radius;
  transition: background-color 200ms, border 200ms, color 200ms, box-shadow 400ms ease;

  &:visited,
  &:active,
  &:focus {
    color: $white;
  }

  &:hover {
    background-color: darken($primary-color, 10%);
    box-shadow: none;
    color: $white;
  }
}

//////////////////
//button - 4 style combinations
@mixin button($outline: false, $reverse: false, $large: false, $alt: false) {
  @include button-base;

  @if $large == true {
    font-size: rem-calc(15);
    padding: ($button-padding * 0.75) ($button-padding * 2.25);
  }

  @if $outline == true {
    background-color: transparent;
    box-shadow: none;
    color: $primary-color;
    border: 1px solid $primary-color;

    &:visited,
    &:active,
    &:focus {
      color: darken($primary-color, 2%);
    }

    &:hover {
      background-color: darken($primary-color, 2%);
      color: $white;
    }
  }

  @if $reverse == true {
    background-color: darken($primary-color, 10%);
    box-shadow: 0 10px 20px rgba($blue-shadow, 0.4);
    color: $white;

    &:visited,
    &:active,
    &:focus {
      color: $white;
    }

    &:hover {
      background-color: $secondary-color;
      box-shadow: none;
      color: $white;
    }

    @if $outline == true {
      background-color: transparent;
      color: $white;
      border: 1px solid rgba($secondary-color, 0.5);

      &:visited,
      &:active,
      &:focus {
        color: $white;
      }

      &:hover {
        background-color: $blue-light;
        border-color: rgba($blue-light, 0.6);
        color: $white;
      }
    }
  }

  @if $alt == true {
    background-image: none;
    box-shadow: none;
    border-color: transparent;
    border-radius: 0;
    background-color: transparent;
    color: $secondary-color;
    text-transform: none;

    &:visited,
    &:active,
    &:focus {
      color: $secondary-color;
    }

    &:hover {
      background-image: none;
      color: $primary-color;
      background-color: transparent;
      border-color: transparent;
    }
  }

  @content;
}
