// Individual careers page view.
.careers-index-view {

  .page-main {
    @include grid-row;
    max-width: $grid-row-narrow;

    .page-title-wrapper {
      @include grid-column(12);
    }

    .career-inner {
      @include grid-row;

      .career-column {
        @include grid-column(12);

        &.column1 {
          @include component-margin-bottom;

          @include breakpoint(medium) {
            @include grid-column(8);
          }
        }

        &.column2 {
          @include grid-column(12);

          @include breakpoint(medium) {
            @include grid-column(4);
          }
        }
      }
    }

    // Main career details.
    .career-details {

      .company-info,
      .job-description,
      .job-additional-info,
      .job-benefits {
        margin-bottom: $global-margin;
      }
    }

    // Sidebar callouts.
    .career-callout {

      > div {
        margin: $global-margin auto;
      }

      .action-list {
        list-style-type: none;
        margin-left: 0;

        li {
          margin: $global-margin auto;

          a {
            display: block;
          }
        }
      }

      .job-video {

        iframe {
          width: 100%;
          max-width: 100%;
          height: auto;
        }
      }
    }
  }
}
