.catalog-product-view {

  .subscription-wrapper {
    background: $grey-lighter;
    margin-bottom: 2rem;
    padding: 2rem 2rem 1rem;
    color: $deep-black;

    .subscription-cta {
      color: $deep-black;
      margin-bottom: 1rem;
    }

    input {
      &[type='checkbox'] {
        transform: scale(1.5);
        vertical-align: baseline;
      }

      &[type='number'] {
        text-align: center;
      }
    }

    .subscription-frequency-wrapper {
      display: none; // initial toggle state

      &.active {
        display: block;
      }

      .subscription-cta {
        font-weight: 600;
        margin-bottom: 0;
      }

      .columns {
        float: left;
        padding-left: 0;

        // Arrrrrg! Sass-lint! I've been writing SASS/SCSS before sass-lint was invented.
        // I know what I'm doing
        &:last-child:not(:first-child) {
          float: left;
        }
      }

      select {
        min-height: 2rem;
      }
    }

    ul {
      color: $deep-black !important;
    }

    a {
      text-decoration: underline;
    }

    // remove vertical pipe (|) between links (i.e., login or create an account)
    a + a {
      border-left: none;
      margin-left: 0;
      padding-left: 0;
    }
  }

  // Schedule and save price callouts.
  .subscription-price {
    background-color: $grey-lighter;
    padding: $global-padding / 2;
    margin-bottom: $global-margin / -2;

    .pdp-price {
      font-weight: bold;
      color: $blue-dark;
    }
  }
}

.catalog-product-view .product-info-main .product-add-form {

  .subscription-wrapper {

    .label {
      color: $deep-black;
      font-size: 1.2rem;
      font-weight: bold;
    }
  }

  .interval-error-message {
    clear: both;
    color: $red;
    display: block;
    font-weight: bold;
  }

  [type="submit"]:disabled {
    cursor: not-allowed;

    &:hover {
      color: $primary-color;
      cursor: not-allowed;
    }
  }
}
