
.block.careers-header {
  position: relative;
  margin: 0 auto;

  > h1 {
    position: absolute;
    left: 0;
    right: 0;
    top: 40%;
    color: $white;
    text-align: center;
    z-index: 1;
    text-shadow: 0 10px 15px rgba($black, 0.4);
  }

  > p {
    display: flex;
    position: relative;

    img {
      flex: 1;
      object-fit: cover;
      width: 33%;

      &:not(:first-of-type) {
        margin-left: $global-margin * 0.75;
      }

      &:not(:first-of-type) {

        // on small screens all but the first image is hidden
        @include breakpoint(small only) {
          display: none;
        }
      }
    }

    &:after {
      position: absolute;
      content: '';
      background-color: rgba($black, 0.3);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }
  }
}
