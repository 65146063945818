// Page Footer
footer {
  width: 100%;
  position: relative;
  z-index: 1;
  background-color: rgba($grey, 0.1);
  padding: $global-padding;
  margin-top: $global-margin * 2;

  .footer.content {
    @include grid-row;

    @include breakpoint(medium) {
      padding: 0 ($global-padding * 4);
    }
  }

  .block.newsletter {

    @include grid-column(12);
    margin-bottom: $global-margin * 2;

    @include breakpoint(medium) {
      @include grid-column(5);
      float: right;
    }
  }

  .footer.links {
    display: inline-block;
    margin-left: 0;
    vertical-align: top;
    list-style: none;

    @include breakpoint(small only) {
      width: 100%;
    }

    li {
      font-size: $global-font-size;
      margin-bottom: $global-margin / 2;

      @include breakpoint (small only) {
        padding: $global-padding;
        background-color: darken($grey-light, 5%);

        &:hover {

          background-color: darken($grey-light, 15%);
        }
      }

      a {
        display: block;
      }
    }
  }
}
