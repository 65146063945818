.checkout-cart-index {

  .cart-container {


    .cart-summary {

      .title:not(span):not(.summary) {
        color: $secondary-color;
        padding: ($global-padding / 3) 0;
        background-color: transparent;
        outline: none;
        cursor: pointer;

        strong {
          font-weight: $font-weight-medium;
        }

        &::before {
          margin-left: 0;
          margin-right: 0;
        }
      }

      .block.shipping {
        border-top: 1px solid $grey-light;
        padding-top: $global-padding / 2;
        padding-bottom: $global-padding / 2;
      }
    }
  }
}

