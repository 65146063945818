//Footer newsletter block
footer {
  
  > .footer.content {

    .block.footer-social {

      > .block.newsletter {
        width: 100%;
        padding: 0;
        margin-bottom: 0;

        .form.subscribe {
          @include grid-row;
          color: rgba($blue-dark, 0.8);

          > * {
            padding-left: 0 !important;
            padding-right: 0 !important;
          }

          .field.newsletter {
            @include grid-column(10);

            > .control {
              @include icon($position: before, $icon-set: fontawesome, $icon: envelope){
                font-size: $global-font-size * 1.25;
                position: absolute;
                top: $global-padding / 2;
                left: $global-padding / 2;
              };
              position: relative;

              input {
                border-radius: $global-radius 0 0 $global-radius;
                height: $global-padding * 3;
                padding-left: $global-padding * 2;
                background-color: $white;
              }
            }
          }

          .actions {
            @include grid-column(2);
          }

          .action.subscribe {
            margin-top: 0;
            height: $global-padding * 3;
            border-radius: 0 $global-radius $global-radius 0;
            position: inherit;
            width: auto;
            font-family: 'fontawesome';
            box-shadow: none;
          }
        }

        .title {
          color: $white;
          font-size: $global-font-size * 1.125;
          font-weight: bold;
          margin-bottom: $global-margin;
        }

        .mage-error {
          background-color: $white;
          padding-left: $global-padding;
          padding-right: $global-padding;
        }
      }
    }
  }
}
