
.block.medicaid-form-container {
  @include grid-row;
  max-width: $grid-row-narrow;

  > .group {
    @include grid-column(12);

    @include breakpoint(medium) {
      @include grid-column(6);
    }
  }

  .block.medicaid-fees {
    @include grid-column(12);
  }

  // Title and intro text group.
  .group.intro {

    > .medicaid-image {
      width: 100%;
      height: $global-padding * 15;
      margin-bottom: $global-margin * 2;
    }

    ul {

      > li {
        margin-bottom: $global-margin / 2;
      }
    }



    > .medicaid-gallery {

      // Image gallery is hidden on mobile.
      @include breakpoint(small only) {
        display: none;
      }
    }

    .aob-link {
      margin-top: $global-margin;
    }

    .medicaid-aob-index & {

      .fees-toggle,
      .aob-link {
        display: none;
      }
    }
  }



  // Form container group.
  .group.form-wrapper {

    @include breakpoint(small only) {
      margin-top: $global-margin;
    }

    .form-content {
      background-color: $primary-color;
      margin-bottom: $global-margin;
      padding: $global-padding * 2;

      .form-top {

        h2,
        p {
          color: $white;
          margin-bottom: 0;
        }
      }
      // Form specific styles are handled in the _form--medicaid-eligibility.scss file.
      // AOB form specific styles ar in the _form--medicaid-aob.scss file
    }

    > .disclaimer {
      color: $grey;
    }

    .medicaid-aob-index & {
      .form-content {
        margin-bottom: 2rem;
      }
    }
  }
}
