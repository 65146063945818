
.cart.table-wrapper {

  table {

    @include breakpoint(small only) {
      @include table-stack($header: false);

      tr {
        
        td.col.price,
        td.col.qty,
        td.col.subtotal  {

          &::before {
            font-weight: bold;
            margin-right: $global-margin / 3;
          }
        }

        td.col.price {

          &::before {
            content: 'Price:';
          }
        }

        td.col.qty {

          &::before {
            content: 'Quantity:';
          }

          > div {
            display: inline-block;
            margin-bottom: $global-margin * -2;
          }
        }

        td.col.subtotal {

          &::before {
            content: 'Subtotal:';
          }
        }
      }
    }

    td {
      
      &.col.price,
      &.col.subtotal {

        @include breakpoint(medium) {
          font-size: $global-font-size * 1.25;
          padding-top: $global-padding * 1.75;
        }
      }
  
      &.col.qty {

        @include breakpoint(medium) {
          padding-top: $global-padding * 1.5;
        }

        label {
          display: none;
        }
      }
    }

    th {
      
      &.col.qty {
        width: 15%;
      }
    }
  }

  .product-item-photo,
  .product-item-details {
    display: table-cell;
    vertical-align: top;
  }

  .product-item-photo {
    padding-right: $global-padding * 2;
  }
}
