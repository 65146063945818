
.checkout-onepage-success {

  .actions-toolbar {

    .primary {
      float: none;
    }
  }
}
