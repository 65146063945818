
.rating-summary {

  .label {
    display: none;
  }
}

.rating-result {
  @include icon($position: before, $icon: star, $icon-set: fontawesome, $styles: true) {
    content: '\f005 \f005 \f005 \f005 \f005';
    color: transparent;
  };
  width: auto;
  display: inline-block;
  position: relative;
  margin-bottom: 0;
  overflow: initial;

  > span {
    @include icon($position: before, $icon: star, $icon-set: fontawesome, $styles: true) {
      content: '\f005 \f005 \f005 \f005 \f005';
      color: $orange;
    };
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;

    > span {
      display: none;
    }
  }
}

.review-control-vote {
  overflow: hidden;

  &:before {
    color: #c7c7c7;
    font-family: 'fontawesome';
    font-size: 16px;
    height: 16px;
    letter-spacing: 2px;
    line-height: 16px;
    font-style: normal;
    font-weight: normal;
    speak: none;
    vertical-align: top;
    content: '\f005 \f005 \f005 \f005 \f005';
    display: block;
    position: absolute;
    z-index: 1;
  }

  input[type="radio"] {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;

    &:focus + label:before,
    &:checked + label:before {
      opacity: 1;
    }
  }

  label {
    cursor: pointer;
    display: block;
    position: absolute;
    margin: 0;

    span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    &:before {
      color: $orange;
      font-family: 'fontawesome';
      font-size: 16px;
      height: 16px;
      letter-spacing: 2px;
      line-height: 16px;
      font-style: normal;
      font-weight: normal;
      speak: none;
      vertical-align: top;
      opacity: 0;
    }

    &:hover {

      &:before {
        opacity: 1;
      }

      ~ label:before {
        opacity: 0;
      }
    }
  }

  .rating-5 {
    z-index: 2;

    &:before {
      content: '\f005 \f005 \f005 \f005 \f005';
    }
  }

  .rating-4 {
    z-index: 3;

    &:before {
      content: '\f005 \f005 \f005 \f005';
    }
  }

  .rating-3 {

    z-index: 4;
    &:before {
      content: '\f005 \f005 \f005';
    }
  }
  .rating-2 {
    z-index: 5;

    &:before {
      content: '\f005 \f005';
    }
  }

  .rating-1 {
    z-index: 6;

    &:before {
      content: '\f005';
    }
  }

  &:before {
    content: '\f005 \f005 \f005 \f005 \f005';
  }
}

.review-toolbar {
  border-top: 1px solid #c9c9c9;
  margin: 0 0 20px;
  padding: 0;
  text-align: center;

  &:first-child {
    display: none;
  }

  .pages {
    border-bottom: 1px solid #c9c9c9;
    display: block;
    padding: 20px 0;
    text-align: center;
  }

  .limiter {
    display: none;
  }
}

.review-add .block-title {
  display: none;
}

.fieldset {

  .review-legend.legend {
    border-bottom: 0;
    line-height: 1.3;
    padding: 0;
    margin-bottom: 20px;

    span {
      margin: 0;
    }

    strong {
      display: block;
      font-weight: 600;
    }
  }

  .review-field-ratings > .label {
    font-weight: 600;
    margin-bottom: 10px;
    padding: 0;
  }
}

.review-control-vote {

  label:before,
  &:before {
    font-size: 32px;
    height: 32px;
    letter-spacing: 10px;
    line-height: 32px;
    margin: 0;
  }
}

.review-field-rating {

  .label {
    font-weight: 600;
  }

  .control {
    margin-bottom: 48px;
    margin-top: 10px;
  }
}

.product.details.product-item-details {

  .product-reviews-summary {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
}
