// There is WAY Too many !important tags in here
// this will need to be cleaned up and inheritance fixed
.page-header {
  background-color: $primary-color;
  position: relative;

  > .panel.wrapper {
    background-color: transparent !important;
    position: relative;
    border-bottom: none !important;
  }

  > .header.content {
    @include grid-row;
    clear: both;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: $global-padding * 2 !important;
    padding-bottom: 0 !important;

    > .logo {
      @include grid-column(12);
      position: relative;
      text-align: left;
      margin-bottom: $global-margin !important;

      @include breakpoint(medium) {
        @include grid-column(2);
        margin-top: $global-padding * -2 !important;
      }

      img {
        display: none;

        @include breakpoint(medium) {
          display: block;
          width: rem-calc(75);
          max-width: none;
          vertical-align: top;
          padding-top: $global-padding / 2;
          margin: ($global-margin * 0.75) 0 0 20px !important;
        }

        @media only screen and (min-width: 776px) and (max-width: 1024px) {
          margin: ($global-margin * 0.75) 0 0 0 !important;
        }
      }

      &:after {
        content: '';
        background-image: url('../images/mobilelogo.svg');
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        height: rem-calc(33);
        position: absolute;
        top: $global-padding * -1;
        left: 0;
        right: 0;

        @include breakpoint(medium) {
          display: none;
        }
      }
    }

    .block.block-search {
      @include grid-column(12);
      margin-top: $global-margin;

      @include breakpoint(medium) {
        @include grid-column(4.5);
        margin-top: $global-margin / 2;
      }
    }

    .block.header-promo {
      @include breakpoint(medium) {
        @include grid-column(2);
        margin-top: $global-margin / -4;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }

    .minicart-wrapper,
    .myaccount {
      @include breakpoint(medium) {
        @include grid-column(1.25);
        display: block;
        float: left !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin-top: $global-margin / -2;
        margin-right: $global-margin;
      }
    }

    .minicart-wrapper {
      @include breakpoint(medium) {
        margin-top: $global-margin / -4;
      }
    }
  }

  .panel.header {
    display: none;
  }
}

// Page specific page header styling
.cms-home {
  .page-header {
    margin-bottom: 0 !important;
  }
}

.checkout-index-index {
  header {
    > .header.content {
      > .logo {
        float: left !important;
      }
    }
  }
}
