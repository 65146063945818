.modal-custom.authentication-dropdown {

  .modal-header {
    padding: ($global-padding * 1.5) ($global-padding * 2) !important;
  }

  .action-close,
  .action-login {
    float: right;
  }

  .action-close {
    @include icon($position: after, $icon: close) {
      margin: $global-margin / 2;
    };
    position: absolute;
    top: 0;
    right: 0;
    float: right;
    background: transparent;
    border: none;
    color: $grey;
    font-size: $global-font-size * 1.7;

    span {
      display: none;
    }

    &:hover {
      color: $primary-color;
    }
  }

  .block-customer-login {
    margin-top: $global-margin * -2;

    .block-title {
      color: $secondary-color;
      font-size: 1.25rem;
      padding-left: $global-padding;
      font-weight: 700;
      text-transform: uppercase;
    }

    .fieldset {
      margin-top: 0;
    }
  }
}

// Login error modal.
.loginError {

  .modal-inner-wrap {
    padding: $global-padding * 2;

    .modal-content {

      .button {
        display: block;
        margin: $global-margin auto;
      }
    }
  }
}

//Custom modal overlay - used on checkout pages
body {

  &.hide-listrak-newsletter-popup {

    .simpleltkmodal-overlay,
    .simpleltkmodal-container {
      display: none !important;
    }
  }

  &._has-modal {

    .modals-wrapper {
      display: block;
    }

    .modal-popup {
      display: none;
    }

    .modal-popup._show {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10 !important;
      background-color: rgba($black, 0.6);

      .modal-inner-wrap {
        width: 90%;
        margin: 0 auto ($global-margin * 14);
        position: relative;
        top: $global-padding * 6;
        background-color: $white;
        border-radius: $global-radius;
        box-shadow: 0 15px 40px $grey-shadow-transparent;
        z-index: 100;
        max-height: 500px;
        overflow-y: auto;
        @include breakpoint(medium) {
          width: 50%;
        }

        .modal-content {
          padding-top: 0;
          padding-bottom: 0;
        }

        .modal-footer {
          float: none;
          box-shadow: none;
          margin-top: $global-margin;
          padding-top: $global-padding;
          border-radius: $global-radius;
          clear: both;
        }

        .block-new-customer {
          margin-top: $global-margin * 3;
        }

        .block-content {
          padding-bottom: $global-padding * 2;

          .actions-toolbar {

            .primary {
              display: inline;
            }

            div.secondary {
              float: right;
            }
          }
        }

        .form-shipping-address {
          padding-bottom: 0;

          .fieldset.address {
            border: none;

            > .field {

              @include breakpoint(medium) {
                @include grid-column(4);
              }
            }

            > .field.street,
            > .field.choice {

              @include breakpoint(medium) {
                @include grid-column(12);
              }
            }

            > [name='shippingAddress.country_id'],
            > [name='shippingAddress.city'],
            > [name='shippingAddress.region_id'],
            > [name='shippingAddress.postcode'] {

              @include breakpoint(medium) {
                @include grid-column(6)
              }
            }

            > [name='shippingAddress.telephone'] {

              @include breakpoint(medium) {
                @include grid-column(12);
              }
            }
          }
        }
      }

      .modal-header {
        text-align: left;
        padding: ($global-padding * 2) $global-padding 0;
        margin-bottom: $global-margin;

        h1 {
          font-size: $global-font-size * 1.5;
        }

        .action-close {
          @include icon($position: after, $icon: close) {
            margin: $global-margin / 2;
          };
          position: absolute;
          top: 0;
          right: 0;
          float: right;
          background: transparent;
          border: none;
          color: $grey;
          font-size: $global-font-size * 1.7;

          span {
            display: none;
          }

          &:hover {
            color: $primary-color;
          }
        }
      }
    }
  }
}

// Listtrack modal overrides.
.simpleltkmodal-container {

  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    color: $white;
  }
}
