// Main navigation styling
.nav-sections-item-content {

  .navigation {
  
    ul {

      @include breakpoint(medium down) {
        width: 100%;
      }
    }
  
    .level0 {

      @include breakpoint(medium down) {
        width: 100%;
        display: block;
        position: relative;
        height: $global-padding * 4;
      }

      > a {

        @include breakpoint(medium down) {
          width: 100%;
        }
      }

      .submenu {
        background: $white;
        border: 1px solid $grey-light;
        box-shadow: 0 5px 5px rgba($black, 0.19);
        font-weight: 400;
        min-width: rem-calc(230);
        padding: $global-padding 0;
        display: none;
        left: 0;
        margin: 0 !important;
        padding: 0;
        position: absolute;
        z-index: 1;
        margin-top: $global-margin * 0.75;
      }
    }
  }
}

// When we open the offcanvas nav - add a background color.
.nav-sections {

  @include breakpoint(medium down) {
    transition: left 0.3s;
    height: 100%;
    left: -80%;
    left: calc(-1 * (100% - 54px));
    overflow: auto;
    position: fixed;
    top: 0;
    width: 80%;
    width: calc(100% - 54px);
  }

  .nav-open & {
    background-color: $white;

    @include breakpoint(medium down) {
      box-shadow: 0 0 5px 0 rgba($black, 0.75);
      left: 0;
      z-index: 99;
      max-width: 95vw;
    }
  }

  &.sections {

    @include breakpoint(medium down) {
      max-width: 95vw;
    }
  }
}
