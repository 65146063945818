.image-slider-owl,
.owl-carousel {
  margin-top: 0;

  .owl-stage-outer {
    padding: 0;
  }

  .banner-item {
    margin: 0 !important;

    .content_slider {
      @include grid-row;
      max-width: $grid-row-narrow;

      @include breakpoint(medium) {
        top: 10%;
        left: 0;
        right: 0;
      }

      @include breakpoint(large) {
        top: 20%;
      }

      .slide_content {
        @include grid-column(12);
  
        @include breakpoint(medium) {
          @include grid-column(6);
        }

        h1 {
          margin-bottom: 0;
        }

        .button {
          margin-top: $global-margin * 1.5;
        }
      }
    }
  }
}

// Owl previous and next arrows - moved out to override and style WeltPixel slider arrows
.owl-theme {

  .owl-controls {

    .owl-nav {

      // .owl-prev,
      // .owl-next {
      //   font-size: 0;
      //   width: rem-calc(45);
      //   height: rem-calc(45);
      //   transition: color 500ms ease, background-color 500ms ease;
      //   background-color: rgba($white, 0.5);
      //   color: $blue-dark;
      //   border-radius: 0;
      //   position: relative;
      //   display: flex;
      //   flex-direction: column;
      //   justify-content: center;
      //   margin-top: -16%;

      //   @include breakpoint(medium) {
      //     width: rem-calc(75);
      //     height: rem-calc(75);
      //   }

      //   &:hover {
      //     background-color: $white;
      //   }
      // }

      .owl-prev {
        // @include icon(before, arrow-left, fontawesome) {
        //   color: $blue-dark;
        //   font-size: $global-font-size * 1.75;
        // };
        // float: left;

        @include breakpoint(medium) {
          left: rem-calc(9) !important;
        }
      }

      .owl-next {
        // @include icon(before, arrow-right, fontawesome) {
        //   color: $blue-dark;
        //   font-size: $global-font-size * 1.75;
        // };
        // float: right;

        @include breakpoint(medium) {
          right: rem-calc(9) !important;
        }
      }
    }
  }
}
.owl-theme .owl-controls .owl-nav [class*=owl-] {
  &.owl-prev {
    &:before {
      position: absolute;
      top: 26px;
      left: 8px;
      z-index: 1;
      width: 20px;
      height: 1px;
      content: "";
      border-top: 2px solid #000;
      transform: rotate(40deg);
      border-radius: 20px;
      text-indent: 0;
    }

    &:after {
      position: absolute;
      top: 14px;
      left: 8px;
      z-index: 1;
      width: 20px;
      height: 1px;
      content: "";
      border-top: 2px solid #000;
      transform: rotate(-40deg);
      border-radius: 20px;
      text-indent: 0;
    }
  }

  &.owl-next {
    &:before {
      position: absolute;
      top: 14px;
      right: 8px;
      z-index: 1;
      width: 20px;
      height: 1px;
      content: "";
      border-top: 2px solid #000;
      transform: rotate(40deg);
      border-radius: 20px;
    }

    &:after {
      position: absolute;
      top: 26px;
      right: 8px;
      z-index: 1;
      width: 20px;
      height: 1px;
      content: "";
      border-top: 2px solid #000;
      transform: rotate(-40deg);
      border-radius: 20px;
    }
  }
}
.owl-theme .owl-controls .owl-nav [class*=owl-]:hover {
  &:before, &:after {
    color: #fff;
  }
}
.custom-slider{
  .owl-carousel{
    &.owl-theme{
      .owl-thumbs{
        display: none;
      }
    }
  }
}