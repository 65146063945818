
.block.brand-callouts {
  @include grid-row;
  margin-bottom: $global-margin * 2;

  > div {
    @include grid-column(12);
  }

  .block.block-static-block {
    @include grid-row;
    padding-bottom: $global-padding * 2;
    border-bottom: 1px solid $grey-light;

    > p {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: center;

      @include breakpoint(medium) {
        justify-content: space-between;
      }

      > * {
        margin-bottom: $global-margin;

        @include breakpoint(small only) {
          width: 33%;
          padding: 0 ($global-padding / 2);
        }
      }
    }
  }
}
