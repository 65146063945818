
.block.footer-bottom {
  position: relative;
  padding-top: $global-padding;

  @include breakpoint(medium) {
    padding-top: $global-padding * 3;
  }

  &::before {
    content: '';
    height: 1px;
    background-color: rgba($white, 0.2);
    width: $global-padding * 71; // this is the width of a grid-row with the padding
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  ul {
    list-style: none;
    margin-bottom: $global-margin / 4;
    margin-left: 0;

    li {
      display: inline-block;
      margin-right: $global-margin;

      a {
        color: $white;
        font-weight: $font-weight-book;

        &:hover {
          color: $yellow;
        }
      }
    }
  }

  .copyright {
    background: transparent;
    color: rgba($white, 0.5);
    font-size: $global-font-size * 0.9;
    padding-top: 0;
    text-align: left;

    p {
      color: rgba($white, 0.5);
      font-size: $global-font-size * 0.9;
      margin-bottom: 0;
    }

    a {
      color: $white;

      &:hover {
        color: $yellow;
      }
    }
  }

  .footer-certifications {
    text-align: center;

    @include breakpoint(medium) {
      text-align: right;
      margin-top: $global-margin * -1;
    }

    img {
      margin: 0 ($global-margin / 2);
    }
  }
}
