.case-callout {
  margin-top: $global-padding * 2;

  .title {
    color: $red;
    display: block;
    font-size: rem-calc(20);
    font-weight: bold;
  }

  .body {
    display: block;
    font-weight: bold;
  }
}
