// Brands category page.
.page-products.page-layout-brands-layout {

  .page-main {
    @include grid-row;
    max-width: $grid-row-narrow;

    > * {
      @include grid-column();
    }

    // this is gross and needs to be done via XML but not working.
    > .sidebar.sidebar-main {
      display: none;
    }
  }
}
