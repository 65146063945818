.cms-page-view {

  &.cms-sample {
    h1 {
      color: $primary-color;
    }

    .block.footer-callouts {
      display: none; // per client's request
    }
  }
}
