
.block.employee-testimonials {
  @include grid-row;
  @include component-margin;
  max-width: $grid-row-narrow;

  > * {
    @include grid-column(12);
  }

  h2 {
    color: $blue-dark;
  }

  ul {
    @include grid-row;
    display: flex;
    list-style-type: none;
    flex-direction: column;

    @include breakpoint(medium) {
      flex-direction: row;
    }

    li {
      @include grid-column(12);
      border: 7px solid $blue-light;
      box-shadow: 0 15px 25px rgba($blue, 0.15);
      border-radius: $global-radius;
      padding: $global-padding * 2;
      margin: $global-margin 0;

      @include breakpoint(medium) {
        @include grid-column(4);
        margin: $global-margin;
      }

      blockquote {
        margin: 0;
        padding: $global-padding ($global-padding * 2);
        border: 0;
        color: $blue-dark;

        p {
          color: $blue-dark;
          margin-bottom: $global-margin / 2;
        }

        footer {
          font-weight: bold;
          background: transparent;
          padding: 0;
          margin-top: 0;

          &:before {
            content: '-';
            margin-right: $global-margin / 2;
            color: $blue-light;
          }

          img {
            border-radius: 50%;
            width: $global-padding * 4;
            height: $global-padding * 4;
            margin-left: $global-margin;
          }
        }
      }
    }
  }
}
