.checkout-cart-index {

  .cart-container {

    .form.form-cart {
      background-color: $white;
    }

    .cart-summary {

      .title:not(span):not(.summary) {
        color: $secondary-color;
        padding: ($global-padding) ($global-padding / 2);
        background-color: scale-color($secondary-color, $alpha: -80%);
        outline: none;
        cursor: pointer;

        &::before {
          margin-left: 0;
          margin-right: 0;
        }
      }

      .cart-totals {

        tbody {

          th,
          td {
            padding: ($global-padding / 2) 0;
            font-weight: 400;
          }
        }

        .grand.totals {

          > * {
            border-top: 1px solid $grey-light;
          }
        }
      }

      .checkout-methods-items {

        li {
          width: 100%;

          button {
            display: block;
            width: 100%;
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    }

    .actions-toolbar {

      a {
        font-size: $global-font-size * 1.25;
        margin-left: $global-margin / 2;
      }
    }

    .cart.main.actions {
      padding-top: $global-padding;
      padding-bottom: $global-padding;

      .continue {
        @include button();
      }

      .clear,
      .update {
        @include button($alt: true) {
          font-weight: 400;
        };

        @include breakpoint(small only) {
          display: none;
        }
      }
    }
  }
}
