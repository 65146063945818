.block-order-details-view {

  .block-content {

    > div {
      padding-top: $global-padding / 2;
      padding-bottom: $global-padding / 2;

      @include breakpoint(medium) {
        @include grid-column;
      }

      @include breakpoint(large) {
        @include grid-column(12);
      }
 
      .box-title {
        @include icon($position: before, $icon: chevron-right, $icon-set: 'fontawesome') {
          margin-right: $global-margin / 2;
        };
      }

      .box-content {
        max-height: 0;
        visibility: hidden;
        opacity: 0;
        background-color: rgba($grey-dark, 0.1);
        transition: max-height 400ms ease, visibility 400ms ease, opacity 400ms ease;
      }

      &.open {

        .box-title {
          @include icon($position: before, $icon: chevron-down, $icon-set: 'fontawesome') {
            margin-right: $global-margin / 2;
          };
        }

        .box-content {
          height: auto;
          padding: $global-padding;
          margin-top: $global-margin;
          max-height: rem-calc(300);
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}
