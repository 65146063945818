// Footer SM icons.
.page-footer {

  .footer-sm {
    display: block;
    clear: both;

    a {
      color: $white;
      font-size: $global-font-size * 1.5;
      margin-right: $global-margin;

      &:hover {
        color: $yellow;
      }
    }

    i {

      > .icon-label {
        visibility: hidden;
        font-size: 0;
        opacity: 0;
      }
    }
  }
}
