//Fotorama Gallery Styles
.fotorama-stretch {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.fotorama-focus-overlay {
  &:after {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    background-color: $primary-color;
    border-radius: inherit;
    content: '';
  }
}

.fotorama__stage__shaft {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  position: relative;

  &:focus {
    box-shadow: none;

    &:after {
      border-radius: inherit;
      bottom: 3px;
      box-shadow: 0 0 3px 1px #68a8e0;
      content: '';
      left: 3px;
      position: absolute;
      right: 3px;
      top: 3px;
      z-index: 1000;
    }
  }
}

.fotorama__stage__frame {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  overflow: hidden;
  text-align: center;

  .fotorama__img {
    height: auto;
    left: 50%;
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    transition-duration: 0.3s;
    transition-property: width, height, top, left;
    vertical-align: middle;
    width: auto;
  }
}

.fotorama__html {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.fotorama__video {
  iframe {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }

  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 32px;
  z-index: 10;
}

.fotorama-video-container {

  &::after {
    background: url('../images/gallery-sprite.png') bottom right;
    bottom: 0;
    content: '';
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 12px;
    width: 100px;
  }
}

.video-thumb-icon {
  position: relative;

  &::after {
    background: url('../images/gallery-sprite.png') bottom right;
    bottom: 0;
    content: '';
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    right: -5px;
    top: 0;
    width: 100px;
  }
}


.product-video {
  bottom: 0;
  height: 75%;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.fotorama__product-video--loaded {
  &::after {
    background: transparent;
  }
}

.fotorama-gpu {
  transform: translateZ(0);
}

.fotorama--fullscreen {
  transform: translateZ(0);
  background: #fff;
  bottom: 0 !important;
  float: none !important;
  left: 0 !important;
  margin: 0 !important;
  position: fixed !important;
  right: 0 !important;
  top: 0 !important;
  width: 100% !important;
  z-index: 1000 !important;

  .fotorama__stage__frame {

    .fotorama__img--full {
      transform: translateZ(0);
      bottom: 0;
      left: 0;
      margin: auto;
      max-height: inherit;
      max-width: inherit;
      position: absolute;
      right: 0;
      top: 0;
      cursor: default;
      display: block;
      height: auto;
      left: 0;
      margin: auto;
      max-height: 100%;
      max-width: 100%;
      top: 0;
      transition: 0.3s linear;
      vertical-align: middle;
      width: auto;
    }

    .fotorama__img {
      display: none;
      bottom: 0;
      left: 0;
      margin: auto;
      max-height: inherit;
      max-width: inherit;
      position: absolute;
      right: 0;
      top: 0;
    }

    .fotorama__img--full.fotorama__img--zoommable {
      cursor: pointer;
      max-height: none;
      max-width: none;
      transition-property: width, height, bottom, right, top, left;
    }

    .fotorama__img--full.fotorama__img--draggable {
      cursor: move;
      transition-property: none;
    }

    iframe {
      left: 80px;
      width: calc(100% - 80px * 2);
    }
  }

  .fotorama__wrap {
    max-width: 100% !important;
  }

  .fotorama__loaded--full {
    .fotorama__img {
      display: none;
    }

    .fotorama__img--full {
      display: block;
    }
  }

  .fotorama__fullscreen-icon {
    background-position: -80px 0;
    display: inline-block;
  }

  .fotorama__nav,
  .fotorama__stage {
    &:before,
    &:after {
      display: none;
    }
  }
}

.fotorama__caption {
  transform: translateZ(0);
  bottom: 0;
  color: $black;
  font-size: 14px;
  left: 0;
  line-height: 1.5;
  position: absolute;
  right: 0;
  z-index: 12;

  a {
    border-bottom: 1px solid;
    border-color: rgba($black, 0.005);
    text-decoration: none;

    &:hover {
      border-color: rgba($black, 0.005);
    }
  }
}

.fotorama__wrap--css3 {

  .fotorama__stage,
  .fotorama__spinner,
  .fotorama__nav,
  .fotorama__stage__frame {
    transform: translateZ(0);
  }

  .fotorama__html {
    transform: translateZ(0);
    transition-duration: 0.3s;
    transition-property: opacity;
    transition-timing-function: linear;
  }

  .fotorama__stage__shaft,
  .fotorama__nav__shaft,
  .fotorama__thumb-border  {
    transition-duration: 0ms;
    transition-property: transform, width;
    transition-timing-function: cubic-bezier(0.1, 0, 0.25, 1);
  }

  .fotorama__arr,
  .fotorama__fullscreen-icon,
  .fotorama__video-play,
  .fotorama__video-close {
    &:not(:focus) {
      transition-duration: 0.3s;
      transition-property: transform, opacity, background-color;
    }
  }
}

.fotorama__stage {
  &:before {
    transform: translateZ(0);
    background-repeat: no-repeat;
    content: '';
    display: block;
    pointer-events: none;
    position: absolute;
    text-decoration: none;
    z-index: 10;
    left: -10px;
    top: -10px;
  }

  &:after {
    transform: translateZ(0);
    background-repeat: no-repeat;
    content: '';
    display: block;
    pointer-events: none;
    position: absolute;
    text-decoration: none;
    z-index: 10;
    right: -10px;
    bottom: -10px;
  }

  max-width: 100%;
  overflow: hidden;
  position: relative;
  // max-height: 35rem;
}

.fotorama__nav {

  &:before {
    transform: translateZ(0);
    background-repeat: no-repeat;
    content: '';
    display: block;
    pointer-events: none;
    position: absolute;
    text-decoration: none;
    z-index: 10;
    left: -10px;
    top: -10px;
  }

  &:after {
    transform: translateZ(0);
    background-repeat: no-repeat;
    content: '';
    display: block;
    pointer-events: none;
    position: absolute;
    text-decoration: none;
    z-index: 10;
    right: -10px;
    bottom: -10px;
  }

  max-width: 100%;
  overflow: hidden;
  position: relative;
  display: none;
  font-size: 0;
  line-height: 0;
  white-space: nowrap;
  z-index: 5;
}

.fotorama-focus {
  outline: 0;
}

.fotorama__nav__frame {
  outline: 0;
  box-sizing: content-box;
  position: relative;
  display: inline-block;

  &:nth-child(2) {
    &:focus {
      .fotorama__thumb {
        &:after {
          left: 1px;
        }
      }

      .fotorama__thumb.fotorama_vertical_ratio {
        &:after {
          top: 1px;
        }
      }
    }
  }

  &:last-child {
    &:focus {
      .fotorama__thumb {
        &:after {
          right: 1px;
        }
      }

      .fotorama__thumb.fotorama_vertical_ratio {
        &:after {
          bottom: 1px;
        }
      }
    }
  }

  &:focus {
    box-shadow: none;

    &:after {
      left: -1px;
      padding: 1px;
      top: -1px;
    }

    .fotorama__thumb {
      z-index: 2;
    }
  }

  .fotorama__video-play {
    display: block;
    opacity: 1;
    background-position: 0 -240px;
    height: 80px;
    width: 80px;
  }
}

.fotorama__fullscreen-icon {
  &:focus {
    outline: 0;
    box-shadow: none;

    &:after {
      border-radius: inherit;
      bottom: 3px;
      box-shadow: 0 0 3px 1px #68a8e0;
      content: '';
      left: 3px;
      position: absolute;
      right: 3px;
      top: 3px;
      z-index: 1000;
    }
  }

  user-select: none;
  cursor: pointer;
  position: absolute;
  z-index: 1000;
  background-position: 0 0;
  display: none;
  height: 80px;
  right: 0;
  top: 0;
  width: 80px;
  z-index: 1000;
}

.fotorama__zoom-out,
.fotorama__zoom-in {
  &:focus {
    outline: 0;
    box-shadow: none;

    &:after {
      border-radius: inherit;
      bottom: 3px;
      box-shadow: 0 0 3px 1px #68a8e0;
      content: '';
      left: 3px;
      position: absolute;
      right: 3px;
      top: 3px;
      z-index: 1000;
    }
  }

  display: none;
  z-index: 1000;
}


.fotorama-transform-disabled {
  transform: none !important;
}

.fotorama__wrap--video {
  .fotorama__stage__shaft {
    transform: none !important;
  }

  .fotorama__stage {
    transform: none !important;
    .fotorama__video-play {
      display: none;
    }
  }

  .fotorama__stage__frame--video {
    transform: none !important;

    .fotorama__img {
      transform: none !important;
      opacity: 0;
    }

    .fotorama__html {
      transform: none !important;
      opacity: 0;
    }
  }

  .fotorama__caption {
    display: none;
  }

  .fotorama__arr {
    opacity: 0 !important;
  }

  .fotorama__fullscreen-icon {
    opacity: 0 !important;
  }
}

.fotorama-transition-for-slide {
  transition-duration: 0ms;
  transition-property: transform, width;
  transition-timing-function: cubic-bezier(0.1, 0, 0.25, 1);
}

.fotorama-no-select {
  user-select: none;
}

.fotorama__wrap {
  user-select: none;
  direction: ltr;
  position: relative;
  z-index: 0;

  .fotorama__pointer {
    cursor: pointer;
  }

  &:not(.fotorama__wrap--toggle-arrows) {
    .fotorama__fullscreen-icon,
    .fotorama__zoom-out,
    .fotorama__zoom-in {
      background-color: rgba($white, 0.3);
      transition: background-color 0.3s ease-in-out;

      &:active,
      &:hover {
        background-color: rgba($white, 0.5);
      }
    }
  }
}

.fotorama__no-select {
  user-select: none;
}

.fotorama__arr {
  user-select: none;
  box-sizing: border-box;
  cursor: pointer;
  position: absolute;
  z-index: 900;
  bottom: 0;
  position: absolute;
  top: 0;
  width: 80px;
  background-color: rgba($white, 0.3);
  transition: background-color 0.3s ease-in-out;

  &:focus {
    box-shadow: none;

    &:after {
      border-radius: inherit;
      bottom: 3px;
      box-shadow: 0 0 3px 1px #68a8e0;
      content: '';
      left: 3px;
      position: absolute;
      right: 3px;
      top: 3px;
      z-index: 1000;
    }
  }

  .fotorama__arr__arr {
    transform: translate3d(-50%, -50%, 0);
    left: 50%;
    position: absolute;
    top: 50%;
    height: 80px;
    width: 80px;
  }

  &:active,
  &:hover {
    background-color: rgba($white, 0.5);
  }
}

.fotorama__video-close {
  user-select: none;
  cursor: pointer;
  position: absolute;
  z-index: 1000;
  background-position: -80px 0;
  height: 80px;
  opacity: 0;
  right: 0;
  top: 0;
  transform: translate3d(80px, -80px, 0);
  transition: opacity 0.3s ease-in-out;
  width: 80px;
}

.fotorama-select,
.fotorama__select {
  user-select: text;
  cursor: auto;
}

.fotorama-content-box {
  box-sizing: content-box;
}

.fotorama-border-box {
  box-sizing: border-box;
}

.fotorama__thumb-border {
  box-sizing: border-box;
  backface-visibility: hidden;
  background-image: linear-gradient(to bottom right, rgba(255, 255, 255, 0.25), rgba(64, 64, 64, 0.1));
  border: 1px solid #ff5501;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 9;
}

.fotorama__caption__wrap {
  box-sizing: border-box;
  background-color: $white;
  padding: 5px 10px;
}

.fotorama--hidden,
.fotorama-hidden,
.fotorama__load  {
  left: -99999px;
  position: absolute;
  top: -99999px;
  z-index: -1000;
}

.fotorama-visible {
  left: auto;
  opacity: 1;
  position: relative;
  top: auto;
  z-index: auto;
}

.transitionDuration {
  transition-duration: 333ms;
}

.transitionDurationZero {
  transition-duration: 0ms;
}

.fotorama__nav__frame--thumb,
.fotorama__nav__frame--dot {
  &:focus {
    .fotorama__thumb {
      box-shadow: none;
      overflow: inherit;

      &:after {
        border-radius: inherit;
        bottom: 3px;
        box-shadow: 0 0 3px 1px #68a8e0;
        content: '';
        left: 3px;
        position: absolute;
        right: 3px;
        top: 3px;
        z-index: 1000;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
      }
    }

    .fotorama__dot {
      &:after {
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
      }
    }

    .fotorama__thumb.fotorama_vertical_ratio {
      &:after {
        left: 2px;
        right: 2px;
      }
    }
  }
}

.fotorama__thumb__arr {
  box-shadow: none;
  background-color: rgba($white, 0.3);
  transition: background-color 0.3s ease-in-out;

  &:active,
  &:hover {
    background-color: rgba($white, 0.5);
  }
}

.fotorama__fullscreen {
  overflow: hidden;
  position: relative;

  .fotorama__zoom-in {
    cursor: pointer;
    display: block;
    height: 80px;
    overflow: hidden;
    position: absolute;
    width: 80px;
    background-position: 0 0 !important;
    top: 0;
  }

  .fotorama__zoom-out {
    cursor: pointer;
    display: block;
    height: 80px;
    overflow: hidden;
    position: absolute;
    width: 80px;
    background-position: 0 -80px !important;
    top: 80px;
  }

  .fotorama__zoom-in.fotorama__zoom-in--disabled,
  .fotorama__video-close
  .fotorama__zoom-out.fotorama__zoom-out--disabled {
    display: none;
  }
}

.fotorama {
  min-width: 1px;
  overflow: hidden;
}

.fotorama__wrap--rtl {
  .fotorama__stage__frame {
    direction: rtl;
  }

  .fotorama__caption {
    left: auto;
    right: 0;
  }
}

.fotorama__wrap--slide {
  .fotorama__stage__frame {
    opacity: 1 !important;
  }
}

.fotorama__stage__frame.fotorama__active {
  z-index: 8;
}

.fotorama__wrap--fade {
  .fotorama__stage__frame {
    display: none;
  }

  .fotorama__stage__frame.fotorama__active,
  .fotorama__fade-rear,
  .fotorama__fade-front {
    display: block;
    left: 0;
    top: 0;
  }

  .fotorama__fade-rear,
  .fotorama__fade-front {
    z-index: 8;
  }

  .fotorama__fade-rear {
    z-index: 7;
  }

  .fotorama__fade-rear.fotorama__active {
    z-index: 9;
  }

  .fotorama__stage {
    .fotorama__shadow {
      display: none;
    }

    &:after,
    &:before {
      display: none;
    }
  }
}

.fotorama__img {
  border: none !important;
  max-width: inherit;
  opacity: 0;
}

.fotorama__error,
.fotorama__loaded {
  .fotorama__img {
    opacity: 1;
  }

  .fotorama__video-play {
    display: block;
    opacity: 1;
  }
}

.fotorama__img--full {
  display: none;
}

.fotorama__nav__shaft {
  left: 0;
  position: relative;
  text-align: left;
  top: 0;
}

.fotorama__nav--dots {
  display: block;
  text-align: center;

  .fotorama__nav__frame {
    height: 30px;
    width: 18px;
  }

  .fotorama__thumb-border,
  .fotorama__nav__frame--thumb {
    display: none;
  }
}

.fotorama__nav--thumbs {
  display: block;

  .fotorama__nav__frame {
    padding-left: 0 !important;

    &:last-child {
      padding-right: 0 !important;
    }
  }

  .fotorama__nav__frame--dot {
    display: none;
  }
}

.fotorama__active {
  .fotorama__dot {
    background-color: $primary-color;
    border-color: $secondary-color;
  }
}

.fotorama__nav__frame.fotorama__active {
  .fotorama__nav__frame {
    border-width: 3px;
    height: 0;
    width: 0;

    &:after {
      left: -3px;
      padding: 3px;
      top: -3px;
    }
  }
}

.fotorama__dot {
  border-radius: 6px;
  border: 1px solid #858585;
  display: block;
  height: 4px;
  left: 6px;
  position: relative;
  top: 12px;
  width: 4px;
}

.fotorama__thumb {
  background-color: transparent;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.fotorama__wrap--no-captions {
  .fotorama__caption {
    display: none;
  }
}

.fotorama__arr--prev {
  left: 0;

  .fotorama__arr__arr {
    background-position: -80px -80px;
  }
}

.fotorama-sprite,
.fotorama__fullscreen .fotorama__zoom-out,
.fotorama__fullscreen .fotorama__zoom-in,
.fotorama__arr .fotorama__arr__arr,
.fotorama__fullscreen-icon,
.fotorama__video-play,
.fotorama__thumb--icon,
.fotorama__video-close {
  background-image: url('../images/gallery.png');
  background-repeat: no-repeat;
}

.fotorama__arr--next {
  right: 0;

  .fotorama__arr__arr {
    background-position: -160px -80px;
  }
}

.fotorama__arr--disabled {
  cursor: default;
  opacity: 0.1;
  pointer-events: none;
}

.fotorama--fullscreen-icons {
  .fotorama__fullscreen-icon {
    display: none;
  }
}

.fotorama__video-play {
  transform: translate3d(-50%, -50%, 0);
  left: 50%;
  position: absolute;
  top: 50%;
  background-position: -80px -160px;
  height: 160px;
  width: 160px;
}

.fotorama__wrap--css2 {
  .fotorama__video-play {
    display: none;
  }
}

.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows {
  .fotorama__arr,
  .fotorama__fullscreen-icon {
    opacity: 0;

    &:focus {
      opacity: 1;
    }
  }
}

.fotorama__wrap--toggle-arrows {
  &:not(.fotorama__wrap--video) {
    .fotorama__video-close {
      display: none;
    }
  }
}

.fotorama__wrap--toggle-arrows.fotorama__wrap--video {
  .fotorama__video-close {
    opacity: 1;
    right: 93px;
    top: 97px;
  }
}

.fotorama__wrap--css2.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows {

  .fotorama__fullscreen-icon,
  .fotorama__arr {
    display: none;

    &:focus {
      display: block;
    }
  }
}

.fotorama__wrap--css2.fotorama__wrap--video {

  .fotorama__fullscreen-icon,
  .fotorama__arr {
    display: none !important;
  }
}

.fotorama__wrap--css3.fotorama__wrap--no-controls.fotorama__wrap--slide.fotorama__wrap--toggle-arrows {

  .fotorama__fullscreen-icon {
    &:not(:focus) {
      transform: translate3d(80px, -80px, 0);
    }
  }

  .fotorama__arr--prev {
    &:not(:focus) {
      transform: translate3d(-120px, 0, 0);
    }
  }
  .fotorama__arr--next {
    &:not(:focus) {
      transform: translate3d(120px, 0, 0);
    }
  }
}

.fotorama__wrap--css3.fotorama__wrap--video {
  .fotorama__fullscreen-icon {
    transform: translate3d(80px, -80px, 0) !important;
  }

  .fotorama__arr--prev {
    transform: translate3d(-120px, 0, 0) !important;
  }

  .fotorama__arr--next {
    transform: translate3d(120px, 0, 0) !important;
  }
}

.fotorama__nav.fotorama__shadows--left,
.fotorama__stage.fotorama__shadows--left {
  &:before {
    background-size: 1px 100%, 5px 100%;
    bottom: 0;
    height: auto;
    top: 0;
    width: 10px;
    background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.3) 75%, transparent), radial-gradient(farthest-side at 0 50%, rgba(0, 0, 0, 0.4), transparent);
    background-position: 0 0, 0 0;
    left: 0;
  }
}

.fotorama__nav.fotorama__shadows--right,
.fotorama__stage.fotorama__shadows--right {
  &:after {
    background-size: 1px 100%, 5px 100%;
    bottom: 0;
    height: auto;
    top: 0;
    width: 10px;
    background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.3) 75%, transparent), radial-gradient(farthest-side at 100% 50%, rgba(0, 0, 0, 0.4), transparent);
    background-position: 100% 0, 100% 0;
    right: 0;
  }
}


.fotorama__nav.fotorama__shadows--top,
.fotorama__stage.fotorama__shadows--top {
  &:before {
    background-size: 100% 1px, 100% 5px;
    height: 10px;
    left: 0;
    right: 0;
    width: auto;
    background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.3) 75%, transparent), radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.4), transparent);
    background-position: 0 0, 0 0;
    top: 0;
  }
}

.fotorama__nav.fotorama__shadows--bottom,
.fotorama__stage.fotorama__shadows--bottom {
  &:after {
    background-size: 100% 1px, 100% 5px;
    height: 10px;
    left: 0;
    right: 0;
    width: auto;
    background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.3) 75%, transparent), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.4), transparent);
    background-position: 0 100%, 0 100%;
    bottom: 0;
  }
}

.fotorama__wrap--no-shadows {
  .fotorama__stage {

    &:before,
    &:after  {
      display: none;
    }
  }

  .fotorama__nav {
    &:before,
    &:after {
      display: none;
    }
  }
}

.fotorama__nav-wrap {
  .fotorama_vertical_ratio {
    .fotorama__img {
      transform: translateY(-50%);
      height: auto;
      position: absolute;
      top: 50%;
      width: 100%;
    }
  }

  .fotorama_horizontal_ratio {
    .fotorama__img {
      transform: translateX(-50%);
      height: 100%;
      left: 50%;
      position: absolute;
      width: auto;
    }
  }
}

.magnifier-thumb-wrapper {
  display: block;
  left: 0;
  position: relative;
  top: 0;
}

.magnifier-lens {
  border: solid 1px #bbb;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 800;
}

.magnify-lens {
  background: rgba(255, 255, 255, 0.5);
  border: solid 1px #bbb;
  color: $white;
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  z-index: 1000;
}

.magnifier-loader-text {
  margin-top: 10px;
}

.magnifier-large {
  position: absolute;
  width: 32%;
  z-index: 100;
}

.magnifier-preview {
  bottom: 0;
  left: 58%;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 215px;

  &:not(.hidden) {
    background-color: $white;
  }

  img {
    left: 0;
    max-width: inherit;
    position: absolute;
    top: 0;
  }
}

.magnify-opaque {
  opacity: 0.5;
}

.magnify-hidden {
  display: none;
}

.fotorama__nav-wrap--vertical.fotorama__nav-wrap {
  display: inline-block;
  left: 0;
  position: absolute;
  top: 0;
}

.fotorama__nav-wrap--vertical {
  .fotorama__nav__shaft {
    background-color: $white;
    width: 100%;

    .fotorama__nav__frame--thumb {
      display: block;
      padding-bottom: inherit !important;
    }
  }

  .fotorama__thumb__arr--left {
    cursor: pointer;
    height: 30px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 1000;
    top: 0;

    .fotorama__thumb--icon {
      transform: rotate(90deg);
      margin: auto;
      width: 30px;
    }
  }

  .fotorama__thumb__arr--right {
    cursor: pointer;
    height: 30px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 1000;
    bottom: 0;

    .fotorama__thumb--icon {
      transform: rotate(90deg);
      margin: auto;
      width: 30px;
    }
  }
}

.fotorama__nav-wrap--horizontal {
  .fotorama__thumb__arr--left {
    bottom: 0;
    cursor: pointer;
    position: absolute;
    top: 0;
    width: 30px;
    z-index: 1000;
    left: 0;

    .fotorama__thumb--icon {
      transform: translate3d(-50%, -50%, 0);
      left: 50%;
      position: absolute;
      top: 50%;
      width: 100%;
    }
  }

  .fotorama__thumb__arr--right {
    bottom: 0;
    cursor: pointer;
    position: absolute;
    top: 0;
    width: 30px;
    z-index: 1000;
    right: 0;

    .fotorama__thumb--icon {
      transform: translate3d(-50%, -50%, 0);
      left: 50%;
      position: absolute;
      top: 50%;
      width: 100%;
    }
  }
}

.fotorama__wrap--only-active {
  .fotorama__nav,
  .fotorama__stage {
    max-width: 99999px !important;;
  }

  .fotorama__stage__frame {
    visibility: hidden;
  }

  .fotorama__stage__frame.fotorama__active {
    visibility: visible;
  }
}

.fotorama__thumb--icon {
  font-size: 0.001px;
  padding-bottom: 30px;
}

.fotorama__thumb__arr--left {
  .fotorama__thumb--icon {
    background-position: -25px -265px;
  }
}

.fotorama__thumb__arr--right {
  .fotorama__thumb--icon {
    background-position: -25px -350px;
  }
}

.magnify-fullimage {
  display: none;
}

.fotorama__video-close.fotorama-show-control {
  opacity: 1;
  transform: translate3d(0, -10px, 0);
}

.gallery-placeholder {

  .loading-mask {
    padding: 0 0 50%;
    position: static;
    height: 66vh;

    img {
      position: absolute;
      top: 45%;
    }
  }
}
