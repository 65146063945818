
.block.why-workathdis {
  @include component-margin;
  @include component-padding;
  background-color: $blue-light;

  > .inner {
    @include grid-row;

    > * {
      @include grid-column(12);
    }

  }
  
  h2 {
    color: $white;
    text-align: center;
  }

  ul {
    @include grid-row;
    list-style: none;
    color: $white;

    li {
      @include grid-column(12);
      margin-top: $global-margin * 2;

      @include breakpoint(medium) {
        @include grid-column(4);
      }

      strong {
        display: block;
      }
    }
  }
}
