.checkout-index-index {

  main {
    position: relative;
    padding-bottom: $global-padding * 5 !important;

    @include breakpoint(medium) {
      padding-bottom: $global-padding * 10 !important;
    }
  }

  .messages {
    .message {
      padding: $global-padding;

      &.error {
        background: $red;
        color: $white;
      }
    }
  }

  fieldset {
    background-color: transparent;

    &:first-child {
      margin: 0;
    }
  }

  .header.content {
    min-height: rem-calc(95);
  }

  form {
    margin-bottom: $global-margin;
    background-color: $white;

    @include breakpoint(medium) {
      padding: $global-padding / 2;
    }

    @include breakpoint(large) {
      padding: $global-padding;
    }

    .fieldset legend {
      background-color: transparent;
      height: inherit;
    }
  }

  .authentication-wrapper {
    position: absolute;
    right: $global-margin * 3;
    top: $global-margin * 0.33;
    z-index: 10;
  }

  .minicart-wrapper {
    display: none;
  }

  .checkout-container {
    padding-bottom: $global-padding * 4;
  }

  .opc-estimated-wrapper {
    color: $black;
    padding: $global-padding;
    margin: $global-margin 0;
    background-color: darken($grey-light, 3%);

    .estimated-label {
      font-weight: bold;

      &::after {
        content: ': ';
      }
    }
  }

  // Checkout and cart progress indicator
  .opc-progress-bar {
    list-style: none;
    margin: $global-margin 0;
    width: 100%;
    display: table;
    font-weight: bold;
    font-size: $global-font-size * 1.2;

    li {
      display: table-cell;
      width: 1%;
      text-align: center;
      background-color: $secondary-color;
      color: $white;
      padding: ($global-padding / 2) 0;
      position: relative;

      &._complete {
        background-color: scale-color($secondary-color, $alpha: -80%);
        color: $secondary-color;
      }
    }

    ._active + li {
      background-color: darken($grey-light, 5%);
      color: $secondary-color;
    }
  }

  // Main checkout and cart wrapper
  .opc-wrapper {
    @include grid-column(12);
    padding-left: 0 !important;
    padding-right: 0 !important;

    @include breakpoint(medium) {
      @include grid-column(8);
      padding-right: $global-padding * 1.25 !important;
    }

    ol {
      margin: 0;
      list-style: none;

      li {
        padding-top: $global-padding * 2;

        .step-title {
          padding: $global-padding;
          background-color: $secondary-color;
          color: $white;
        }
      }
    }

    .label,
    legend {
      color: $grey-dark;
    }

    .field-tooltip-content {
      display: block;
      margin-bottom: $global-margin;
      max-width: rem-calc(335);
    }

    .checkout-shipping-address,
    .billing-address-form {

      .choice {
        margin-bottom: $global-margin;
      }

      .fieldset.address {
        @include grid-row;

        fieldset {
          clear: left;
        }

        > div {
          @include grid-column(12);

          @include breakpoint(small only) {
            padding-left: 0 !important;
            padding-right: 0 !important;
          }

          @include breakpoint(medium) {
            @include grid-column(4);
            padding-left: 0 !important;
            padding-right: $global-padding * 0.875 !important;

            &:last-child {
              float: left !important;
            }
          }

          @include breakpoint(large) {
            padding-right: inherit !important;
          }
        }

        > [name*="telephone"] {

          @include breakpoint(medium) {
            @include grid-column(6);
          }

          @include breakpoint(large) {
            @include grid-column(4);
          }
        }

        > [name*="country_id"] {

          @include breakpoint(medium) {
            @include grid-column(6);
          }

          @include breakpoint(large) {
            @include grid-column(8);
          }
        }

        > fieldset.street {
          @include grid-column(12);
          @media only screen and (min-width: 776px) {
            padding-left: 0 !important;
          }
          @media only screen and (max-width: 767px) {
            padding-left: 0 !important;
          }
          @media only screen and (max-width: 775px) and (min-width: 768px) {
            padding-left: 1.25rem !important;
          }

          @include breakpoint(small only) {
            padding-right: 0 !important;
          }
        }
      }
    }

    .shipping-address-items {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      @include breakpoint(medium) {
        flex-direction: row;
      }

      .shipping-address-item {
        // flex: 1 1 45%;
        display: inline-block;

        @include breakpoint(medium) {
          width: 45%;
        }

        &.not-selected-item {
          border-right: 0;
        }

        &.selected-item {

          .action-select-shipping-item {
            display: none;
          }
        }

        button {
          margin-top: $global-margin;
        }
      }
    }

    .checkout-shipping-address {

      .field.addresses {
        margin-bottom: $global-margin;
        padding: $global-padding;
        padding-bottom: 0;
        background-color: $white;
      }
    }

    .checkout-billing-address {
      padding: $global-padding;
      margin-bottom: $global-margin;
      background-color: rgba($grey-light, 0.3);
    }

    .form.methods-shipping {

      table {
        border-collapse: collapse;

        th:first-child,
        td:first-child {
          padding-left: $global-padding;
        }

        .col.col-carrier {
          display: none;
        }
      }
    }

    .discount-code {

      > .field.choice {
        @include icon($position: after, $icon: chevron-right) {
          margin: $global-margin / 2;
        };
        cursor: pointer;
        outline: none;
      }

      &._active {

        > .field.choice {
          @include icon($position: after, $icon: chevron-down) {
            margin: $global-margin / 2;
          };
        }
      }
    }

    .actions-toolbar {
      margin-top: $global-margin;
      text-align: right;

      .remind {
        @include button($alt: true);
      }
    }
  }

  // Checkout and cart sidebar wrapper
  .opc-sidebar {
    @include grid-column(12);
    display: block;
    background-color: $white;
    margin-top: $global-margin * 2;

    @include breakpoint(medium) {
      @include grid-column(4);
      padding-right: 0 !important;
      padding-left: 0 !important;
    }

    > .modal-inner-wrap {

      .modal-content {

        .title {
          color: $secondary-color;
          font-size: $global-font-size * 1.25;
          padding: ($global-padding) ($global-padding * 1.5);
          font-weight: 700;
          text-transform: uppercase;
          background-color: scale-color($secondary-color, $alpha: -80%);
          margin-bottom: $global-margin;
        }

        .table-totals {

          .discount {

            .title {
              text-transform: none;
              font-size: $global-font-size;
              color: $grey-dark;
              background: transparent;
              padding: 0;
              display: inline-block;
            }

            .coupon {
              color: $secondary-color;
            }
          }
        }

      }

      .modal-header {

        .action-close {
          display: none;
        }
      }

      .table-caption {
        padding-left: $global-padding * 0.625;
        display: none;
      }
    }

    .shipping-information {

      > div {
        padding: $global-padding 0;

        &:not(:last-of-type) {
          border-bottom: 1px solid $grey-light;
        }
      }

      .shipping-information-title {
        font-weight: bold;
        display: flex;
        align-items: baseline;
        justify-content: space-between;
      }
    }

    button {

      &.action-edit {
        @include button($alt: true) {
          text-transform: capitalize;
        };
      }
    }
  }
  button.btn-disabled,
  button.btn-disabled:hover {
    cursor: default;
    background: #ccc!important;
    color: #666!important;
    border:none!important;
    box-shadow: none!important;
  }
  button.btn-disabled:hover:before {
    display: none!important;
  }
  &._has-modal .modal-popup {
    position: fixed!important;
    height: 100%;
    width: 100%;
  }
}
@media only screen and (max-width: 1024px) {
  body.page-products.catalog-category-view .category-cms {display:none!important;}
}
.page-layout-checkout, .checkout-index-index{
  .opc-wrapper{
    .checkout-shipping-address, .billing-address-form{
      .fieldset.address{
        > fieldset.street{
          @include grid-column(12);
          @media only screen and (min-width: 776px) {
            padding-left: 0 !important;
          }
          @media only screen and (max-width: 767px) {
            padding-left: 0 !important;
          }
          @media only screen and (max-width: 775px) and (min-width: 768px) {
            padding-left: 1.25rem !important;
          }
        }
      }
    }
  }
}