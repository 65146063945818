.page-products.page-with-filter {

  .items {
    .item {
      input[type="checkbox"] {
        display: none;
      }
    }
  }
}
