.block.filter {
  background-color: $white;
  box-shadow: 0 15px 40px rgba($black, 0.15);
  padding: $global-padding ($global-padding * 2) ($global-padding * 2);
  border-radius: $global-radius * 0.1875;
  margin-top: 1;
  
  .block-title {
    color: $primary-color;
    position: inherit;
    border: none;
    display: block;
    padding-left: 0;
    text-align: left;
    font-size: $global-font-size * 1.2;
    cursor: auto;
    padding-bottom: 0;

    @include breakpoint(large) {

      &::after {
        display: none;
      }
    }

    &:hover {
      background-color: transparent;
      color: $primary-color;
    }

    > strong {
      position: relative;
      display: block;
      width: 100%;

      @include breakpoint(medium down) {

        &[aria-expanded="false"],
        &[aria-expanded="true"] {

          &::after {
            position: absolute;
            right: 0;
            top: 0;
            color: $primary-color;
          }
        }

        // When closed, show a plus to show more.
        &[aria-expanded="false"] {
          @include icon($position: after, $icon-set: fontawesome, $icon: minus);
        }

        // When open, show a plus to show less.
        &[aria-expanded="true"] {
          @include icon($position: after, $icon-set: fontawesome, $icon: plus);
        }
      }
    }
  }

  .block-subtitle {
    display: none;
  }

  .block-content {

    @include breakpoint(small only) {
      display: none;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: $white;
      z-index: 99;
      overflow: scroll;

      &.active {
        display: block;
      }
    }
  }

  // Now Shopping By
  .filter-current {
    display: block;

    .item {
      position: relative;

      .filter-label {
        display: none;
      }

      .filter-value {
        padding-left: $global-padding;
        padding-top: $global-padding * 0.4;
        font-style: normal;
        font-weight: bold;
        color: $black;
      }

      .action.remove {
        @include icon($position: before, $icon-set: fontawesome, $icon: close) {
          color: $grey;
        };
        position: absolute;
        left: 0;
        right: 0; // left and right 0 make this action full width of the name - could be updated so only the icon is the remove link
        top: 0;

        span {
          @include visually-hidden;
        }
      }
    }

    & + .filter-actions {
      display: block;
      margin-top: $global-margin;

      // If current filter is applied, add border and spacing to separate filter options from current filter choice
      & ~ .filter-options {
        border-top: 1px solid $grey-light;
        margin-top: $global-margin * 2;
        padding-top: $global-padding;
      }
    }
  }

  .filter-options-title {
    border-top: none;
    cursor: auto;
    padding-bottom: $global-padding / 2;
    text-transform: none;
    color: $primary-color;
    line-height: 1;
    padding-right: 0;
    word-break: normal;

    &::after {
      display: none;
    }

    + .filter-options-content {
      display: block;
    }
  }

  .filter-options-content {
    margin: 0;
    padding: 0 $global-padding ($global-padding / 4);

    @include breakpoint(medium) {
      padding: 0 ($global-padding / 4) $global-padding;
      display: block !important;
    }
  }

  .items {
    list-style: none;
    margin: 0;
    padding: 0;

    .item {
      line-height: 1.5;
      margin: ($global-margin / 3) 0;

      > a {
        display: block;
        transition: color 400ms ease;
        color: $black;
        font-weight: normal;
      }

      &:hover {

        > a {
          color: $primary-color;
        }
      }

      .count {
        color: $grey;

        &::before {
          content: '(';
        }

        &::after {
          content: ')';
        }
      }

      .filter-count-label {
        @include visually-hidden;
      }
    }
  }

  // When the fitler is active show our filters.
  &.active {

    .filter-content {
      display: block;
      position: inherit;
    }
  }
}
  