.customer-subscriptions-edit {

  fieldset legend {
    font-size: 1.6rem;
  }

  .subscription-details {

    .product-name {
      font-size: 1.2rem;
      display: block;
    }
  }

  .columns {
    &:last-child:not(:first-child) {
      float: left;
    }
  }

  .field.qty {
    input[name='qty'] {
      max-width: $global-padding * 4;
    }
  }

  .subscription-cta {
    padding-left: $global-padding * 1;
    font-weight: bold;
  }

  .label {
    color: $black;
    font-size: 1rem;
  }

  .change-notice {
    color: $black;
    font-weight: 600;
    margin: 0;
    padding: $global-padding * 1;

    &.warning {
      background-color: #fcd95f;
    }
  }

  .interval-error-message {
    clear: both;
    color: $red;
    display: block;
    font-weight: bold;
  }

  .save:disabled {
    cursor: not-allowed;

    &:hover {
      color: $primary-color;
      cursor: not-allowed;
    }
  }

  .form-subscription-edit {
    select {
      min-height: $global-padding * 2;
    }
  }

  &.account:not(.wishlist-index-index) .actions-toolbar .secondary {
    margin-right: $global-padding * 1.5 !important;
  }
}
