.ves-container {

  .sidebar {
    @include grid-column(12);

    @include breakpoint(medium) {
      @include grid-column(3);
    }
  }

  .column-main {
    @include grid-column;

    @include breakpoint(medium) {
      @include grid-column(9);
    }
  }
}
