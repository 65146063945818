// Product grid for search results and product category pages
.catalogsearch-result-index,
.catalog-category-view {

  .products-list,
  .products.list {

    .product-item {

      .product.details {

        .product-attribute {
          color: $primary-color;
        }

        .price-final_price {

          span {
            font-weight: bold;
            color: $grey-dark;
          }
        }

        .product.actions {

          a {

            // remove our arrow applied to buttons for product buttons
            &::after {
              display: none;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1024px) {
  body.page-products.catalog-category-view .category-cms {display:none!important;}
}