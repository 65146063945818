.page-header {
  
  > .panel.wrapper {

    > .header-banner {

      > .promo {
        @include grid-column(12);
        text-align: center;
        font-size: $global-font-size * 0.95;
        padding-top: $global-padding / 2;

        @include breakpoint(medium) {
          @include grid-column(5);
          text-align: right;
        }

        .header.links {
          float: none;
          display: inline-block;
        }

        ul {
          margin: 0;
        }

        li {
          display: inline-block;
          position: relative;
          margin: 0 $global-margin * 1.25;

          &::after {
            content: "";
            width: 4px;
            height: 4px;
            background-color: $white;
            display: block;
            border-radius: 50%;
            position: absolute;
            right: $global-margin * -1.5;
            top: 42%;
          }

          &:last-child::after {
            display: none;
          }
        }

        a,
        li,
        p {
          color: $white;
        }
      }
    }
  }
}
  