
//Shopping cart and shopping cart modal on checkout page
.cart-container,
.items-in-cart {

  table {

    .label {
      color: $primary-color;
      font-size: $global-font-size;
      padding-left: 0;
    }

    .discount.coupon {
      color: $primary-color;
    }

    .subscription-additional-info {
      clear: both;
      display: block;
    }
  }
}
.cart{
  &.table-wrapper{
    table{
      td{
        &.col{
          &.qty{
            label{
              display: block;
            }
          }
        }
      }
    }
  }
}
