// Account creation page
.customer-account-create {

  .page-main {
    @include grid-row;
    max-width: $grid-row-narrow;
    float: none;
    padding: 0;

    .page-title-wrapper {

      @include breakpoint(medium) {
        padding-left: 0;
      }
    }

    .form.form-create-account {

      .field {

        .control {

          input {
            margin-bottom: 0;
          }
        }
      }
    }

    .create.info,
    .address,
    .create.account:not(.form) {
      @include grid-column(12);
      border: 0;
      margin-top: 0;
      margin-bottom: 0;

      @include breakpoint(medium) {
        @include grid-column(4);
        min-height: rem-calc(1040);
      }

      legend {
        background-color: transparent;
        padding-top: $global-padding;
        height: 0;
        margin-bottom: $global-margin * 1.5;
        color: $secondary-color;
        text-transform: uppercase;
        font-weight: bold;
      }

      br {
        display: none;
      }
    }

    .create.account:not(.form) {
      padding-left: 1.25rem;
      padding-right: 1.25rem;
    }

    .note {
      color: $grey;
    }

    select {

      &.mage-error {
        margin-bottom: 0;

        + .mage-error {
          margin-bottom: $global-margin;
        }
      }
    }

    .actions-toolbar {
      // @include grid-column(12);
      padding-left: 0;
      padding-right: 0;
      padding-bottom: $global-padding;
      margin-top: $global-margin * 2;
    }

    .submit.primary {
      float: none;
      margin-top: 0;
      margin-bottom: $global-margin;
      margin-left: $global-margin;

      @include breakpoint(medium) {
        float: right;
      }
    }

    .action.back {
      // @extend %button;
      @include button($outline: true);
    }

    .privacy-note {
      color: $grey;
      font-size: rem-calc(13);
      margin-top: $global-margin;
    }

    .field-recaptcha,
    .g-recaptcha {
      margin-top: $global-margin * 1.5;
    }
  }
}
