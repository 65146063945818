//Toolbar links to display inline-block
.actions-toolbar {
  
  .primary {

    //Add button styles to primary or secondary links
    a {
      @include button;
    }
  }

  .secondary {

    a,
    .action.back {
      @include button($outline: true);
    }
  }
}

// Disabled checkout buttons on hover
// show "tip" to alert customer to select payment option
.action.checkout {

  &:disabled {

    &:hover {
      position: relative;

      &:before {
        display: none;
        content: 'Please select a payment option';
        color: $grey;
        background-color: $white;
        position: absolute;
        right: 0;
        bottom: $global-padding * -2;
        padding: ($global-padding / 10) ($global-padding);
      }
    }
  }
}
