
.customer-address-index [class*="address"] {
  clear: both;
  background-color: $white;
  padding: $global-padding;
}
.customer-address-index{
  .table-additional-addresses-items {
    overflow-x: scroll;
    display: block;
  }
}

.form-address-edit {
  @include grid-row();

  .field-name-firstname,
  .field-name-lastname,
  .field.city,
  .field.region,
  .field.zip,
  .field.country {
    @include grid-column;

    @include breakpoint(medium) {
      @include grid-column(6);
    }
  }

  .field.zip {
    clear: left; // account for note/hint under city throwing off heights
  }

  .field.company,
  .field.telephone,
  .field.fax {
    @include grid-column();

    @include breakpoint(medium) {
      @include grid-column(4);
    }
  }

  .field.street,
  .field.choice {
    @include grid-column;
  }

  .field.choice {
    display: flex;
    align-items: center;

    @include breakpoint(small only) {
      margin-bottom: $global-margin / 2;
    }
  }

  [type="checkbox"] + label,
  [type="radio"] + label {

    @include breakpoint(medium down) {
      margin-right: $global-margin * -1;
    }
  }

  .note {
    color: $grey;
  }
}

// Set to display none to hide the opc-new-address-form below the new address button on checkout
// part of our workaround for fixing toggle opening and closing issues.
.address-form-hidden {
  display: none;
}
