.sidebar {

  ul,
  ol {
    margin: 0;
    list-style: none;
    position: relative;

    li {

      a {
        padding: ($global-padding / 3) 0;
        display: block;
      }

      .count {
        font-size: $global-font-size * 0.8;
        font-weight: bold;
        color: $grey;
        margin-left: $global-margin / 4;
        display: none;
      }
    }
  }

  dl {
    margin-bottom: 0;
  }

  p,
  dt {
    font-weight: bold;
    margin-top: $global-margin * 1.25;
    margin-bottom: 0;
    font-size: $global-font-size * 1.2;
  }

  .block {
    margin-top: $global-margin;
  }

  .title,
  .block-title {
    border-bottom: 1px solid $grey-light;
    font-size: $global-font-size * 1.2;
  }

  .block-content:not(.filter-content) {
    padding: $global-padding;
    background-color: $grey-light;
  }

  .block-content.filter-content {

    .block-subtitle {
      font-weight: bold;
      margin-top: $global-margin * 1.25;
      margin-bottom: 0;
      font-size: $global-font-size * 1.2;
      font-weight: normal;
    }

    .filter-label,
    .filter-value {
      display: block;
      color: $secondary-color;
    }

    .filter-label {
      font-weight: bold;

      &::after {
        content: ' : ';

      }
    }

    .filter-value {
      font-style: italic;
    }

    .action.remove {
      @include icon($position: before, $icon-set: 'FontAwesome', $icon: delete) {
        font-size: $global-font-size;
      };
      font-size: $global-font-size;
    }
  }

  .block-actions.filter-actions {

    .action.clear.filter-clear {
      @include icon($position: before, $icon-set: 'FontAwesome', $icon: close) {
        font-size: $global-font-size;
        margin-right: $global-margin / 4;
      };
      font-size: $global-font-size;
    }
  }

  ul {
    list-style: none;

    li {
      display: block;

      strong {
        color: $primary-color;
      }

      a,
      strong {
        display: block;
        padding: $global-padding / 4;

        &:hover {
          background-color: rgba($blue-light, 0.5);
        }

        strong {
          padding: 0;
        }
      }
    }
  }

  .field.item.choice {
    position: relative;
    padding: 0 0 ($global-padding / 4);
    margin: 0 0 ($global-margin / 2);
    border-bottom: 1px solid $grey;

    .control {
      position: absolute;
      right: 0;
      top: 0;

      input {
        height: $global-margin;
      }
    }
  }

  .actions-toolbar {

    .primary,
    .secondary {
      float: none;
      max-width: none;
    }

    .action {
      display: block;
      width: 100%;
      text-align: center;
      margin: ($global-margin / 2) 0 0;
      padding: ($global-padding * 0.55) ($global-padding * 1.25);
    }
  }

  .logout {

    li {
      display: block;

      a {
        @include icon(before, lock, fontawesome) {
          color: $primary-color;
          font-size: $global-font-size * 1.5;
          margin-right: $global-margin;
        };

        display: block;
        list-style: none;
        padding: $global-padding 0 $global-padding ($global-padding / 4);
        border-top: 1px solid $grey-light;
        margin-top: $global-margin;

        &:hover {
          background-color: rgba($blue-light, 0.5);
        }
      }
    }
  }

  // Account navigation sidebar
  .account-nav {

    .title {
      font-size: $global-font-size * 1.3;
    }
  }
}
