//Autocomplete search results - set to drop over page content not push page content down
.searchautocomplete__autocomplete {

  position: absolute;
  background-color: $grey-light;
  border: 1px solid $grey;
  border-radius: $global-radius;
  margin-top: $global-margin;
  display: none;

  ul {
    list-style: none;

    li {

      img {

        display: block;
        margin: 0 auto;
      }
    }
  }

  //Search results autocomplete hidden by default and shown when active and class "_active" is added
  &._active {

    display: block;
  }

//Show all and Search Title centered with background-color and padding to break up list
  .show-all-results,
  .index-title {

    text-align: center;
    background-color: $white;
    border-radius: $global-radius;
    padding: $global-padding;

    a {

      display: block;
    }
  }

  .index-title {

    margin-bottom: $global-margin;
  }
}
