// Redeem code submission form.
.form.redeem-code {
  @include grid-row;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  > .fieldset {
    @include grid-column();
    background: transparent;
    margin-bottom: 0;

    @include breakpoint(small only) {
      padding-bottom: 0;
    }

    @include breakpoint(medium) {
      @include grid-column(10);
    }

    .input-text {
      height: $global-padding * 3;
    }

    .label {
      visibility: hidden;
      opacity: 0;
      font-size: 0;
    }
  }

  > .actions-toolbar {
    @include grid-column();

    @include breakpoint(medium) {
      @include grid-column(2);
    }

    .button {
      background-color: $white;
      color: $primary-color;
    }
  }
}
