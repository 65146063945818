// Redeem Code block styling.
.block.redeem-code {
  

  > .inner {
    @include grid-row;
    background-color: $secondary-color;

    > div {
      @include grid-column(12);
    }

    > .group {
      @include grid-row;
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      .form-content {
        @include grid-column;

        @include breakpoint(medium) {
          @include grid-column(7);
        }
      }

      .redeem-code-image {
        // background-image: url('../images/tryhdis-redeemheader.jpg');
        display: none;

        @include breakpoint(medium) {
          @include grid-column(5);
          display: inline-block;
          text-align: right;
        }
      }
    }
  }

  h2 {
    color: $white;
    text-align: center;
  }

  // Toggle to open example modal.
  .redeem-toggle {

    @include breakpoint(medium) {
      padding-left: $global-padding * 2;
    }

    button {
      color: $white;
      padding-left: 0;
      font-weight: bold;

      @include breakpoint(medium) {
        font-size: $global-font-size * 1.2;
      }
    }
  }

  // Redeem code example modal hidden on load.
  .redeem-example {
    max-height: 0;
    visibility: hidden;
    opacity: 0;
  }
}

// Open redeem example modal.
.modal-content {

  .redeem-example {

    p {
      padding-right: $global-padding;
    }

    img {
      margin: 0 auto;
      display: block;
    }
  }
}
