//Product toolbar for search and advanced search results
.toolbar {

  // Pagination
  .pages {

    .item {
      border-radius: 50%;
      padding: 0 ($global-padding * 0.75);

      &:not(:last-of-type) {
        margin-right: $global-margin / 4;
      }
    }
  }

  // hide sort by dropdown
  .toolbar-sorter.sorter {
    display: none !important;
  }

  // toolbar amount can be hidden on subscriptions toolbar
  .customer-subscriptions-index & {

    .toolbar-amount {
      display: none;
    }
  }

  // Bottom Toolbar - product reviews and product orders within my account.
  &.bottom {
    margin: ($global-margin * 2) auto;

    .toolbar-amount {
      position: initial;
    }

    .limiter {
      display: block;
      float: right;

      > * {
        display: inline-block;
      }

      select {
        width: rem-calc(75);
      }
    }
  }
}


.field.limiter,
.limiter,
.toolbar-sorter.sorter   {
  float: left;
  display: table;

  > * {
    display: table-cell;
  }

  >:first-child {
    padding-right: $global-padding;
  }

  .limiter-text {
    padding-left: $global-padding;
  }

}
