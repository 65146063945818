

.fotorama__nav__frame--thumb {
  opacity: 0.3;

  &.fotorama__active {
    opacity: 1;
  }
}

.fotorama__thumb-border {
  border: 1px solid $secondary-color;
}
