//Customer Account Orders View Page
.sales-order-view {

  .order-items {

    ul {

      list-style: none;
      margin-left: 0;
    }
  }

  // Remove second instance of "Order Items" - on top of order table as a link set
  // Remove third instance of "Order Items" - inside order table as a caption
  .table-order-items caption,
  .order-links {

    font-size: 0;
    visibility: hidden;
  }

  .order-date {

    .label {

      color: $grey;
      background-color: transparent;
      padding-left: 0;
    }
  }

  .order-actions-toolbar {

    .actions {

      margin: $global-margin 0;

      a {

        @extend %button;
        @include button-style;
      }
    }
  }
}

.block-order-details-view {

  .block-content {

    > div {

      @include breakpoint(medium) {
        @include grid-column;
      }

      @include breakpoint(large) {
        @include grid-column(3);
      }
    }
  }

  &:after {
    clear: both;
  }
}
