
.block.footer-callouts {
  // clear: both;
  padding-top: $global-padding * 3;
  background-color: $blue-light;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: $global-margin * 2;

  @include breakpoint(medium) {
    flex-direction: row;
    padding-bottom: $global-padding * 3;
  }
  @media only screen and (max-width: 775px) {
    padding-top: 0;
  }

  // Medicaid and healthcare callout blocks
  .widget.block {
    position: relative;
    padding: ($global-padding * 2) $global-padding;
    margin-bottom: $global-margin;
    text-align: center;

    @include breakpoint(small only) {

      &:first-of-type {
        border-bottom: 2px solid rgba($white, 0.2);
        margin-bottom: $global-margin * 2.5;
      }
    }

    // on medium and up we add in padding and include icons
    @include breakpoint(medium) {
      padding: 0 $global-padding * 8;
      text-align: left;
    }

    &:first-of-type,
    &:last-of-type {

      &:before {
        content: '';
        background: url(../images/sprite-1x.png) no-repeat  -134px -17px;
        position: absolute;
        left: 45%;
        top: $global-padding * -1;
        width: rem-calc(50);
        height: 50%;

        @include breakpoint(medium) {

          top: 0;
          left: $global-padding * 4;
          height: 100%;
        }
        @include breakpoint(medium down) {
           background: url(../images/sprite-1x.png) no-repeat -167px -246px;
        }
      }
    }

    &:last-of-type {
      border-left: 2px solid rgba($white, 0.2);

      &:before {
        background: url("../images/sprite-1x.png") no-repeat -198px -14px;
        width: 54px;
        @include breakpoint(large) {
          left: 3.7rem;
        }
        @include breakpoint(medium down) {
          background: url(../images/sprite-1x.png) no-repeat -198px -20px;
        }
      }
    }

    h3,
    p,
    a {
      color: $white;
    }

    h3 {
      font-size: rem-calc(24);
      margin-bottom: 0;
    }

    a {
      border-bottom: 2px dashed rgba($white, 0.2);
      padding-bottom: $global-padding / 4;
      transition: border 300ms ease, color 300ms ease;

      &:hover {
        color: $yellow;
        border-color: $yellow;
      }
    }
  }
}
