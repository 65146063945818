//Form styling
[type=text],
[type=text]:focus,
[type=password],
[type=password]:focus,
[type=date],
[type=date]:focus,
[type=datetime],
[type=datetime]:focus,
[type=datetime-local],
[type=datetime-local]:focus,
[type=month],
[type=month]:focus,
[type=week],
[type=week]:focus,
[type=email],
[type=email]:focus,
[type=number],
[type=number]:focus,
[type=search],
[type=search]:focus,
[type=tel],
[type=tel]:focus,
[type=time],
[type=time]:focus,
[type=url],
[type=url]:focus,
[type=color],
[type=color]:focus,
input,
input:focus,
textarea,
textarea:focus,
select,
select:focus {
  background-color: $white;
  border-radius: 0;
  color: $grey-dark;
}

// This will remove the lovely dropdown arrows added by default and use the foundation option.
.select,
select {
  // background-image: none;
  -webkit-appearance: none; // sass-lint:disable-line no-vendor-prefixes
  -moz-appearance: none; // sass-lint:disable-line no-vendor-prefixes
  appearance: none;
}

fieldset,
.fieldset {
  background-color: $white;
  border: 0;

  legend {
    margin: 0;
    margin-left: $global-margin * -0.1875;
    padding: ($global-padding / 2) ($global-padding * 0.1875) 0;
    background: transparent;
    height: 0;
    margin-bottom: $global-margin / 2;

    > span {
      display: block;
      margin-top: $global-margin;
    }
  }
}

label {

  &.label {
    font-size: $global-font-size * 0.9;
  }
}

//required asterisk on forms
.required,
._required {

  > .label {

    span {

      &::after {
        content: '*';
        color: $secondary-color;
        margin-left: $global-margin / 6;
      }
    }
  }
}

.hint,
.note {
  font-size: $global-font-size * 0.8;
  margin-bottom: $global-margin;
}

// Mage error warning
.mage-error,
.error {
  color: scale-color(map-get($foundation-palette, alert), $alpha: -40%);
}

input.error,
select.error {
  border: 3px solid scale-color(map-get($foundation-palette, alert), $alpha: 0%);
  margin-bottom: 0;
}

.field-error {
  background: scale-color(map-get($foundation-palette, alert), $alpha: 0%);
  color: $white;
  display: block;
  font-size: rem-calc(12);
  padding: .25rem;
}

// Form builder (/sample, /freemonth, /free-catalog)
.cms-free-catalog,
.cms-free-sample,
.cms-free-month {

  .formbuilder.form {
    select {
      min-height: 2rem;
    }

    .row {

      > .col-sm-4:first-child label {
        line-height: 2.5;
        margin-bottom: $global-padding / 4;
      }

      > .field:nth-of-type(4) {
        clear: both;
      }
    }
  }
}
