.block-search {
  z-index: 10; // get it to appear above/over the logo

  .form.minisearch {
    .label,
    .actions {
      display: none;
    }

    .field-search {
      .input-text {
        font-size: rem-calc(14) !important;
      }
    }

    input::placeholder {
      color: $grey-placeholder;
      font-size: rem-calc(14);
    }

    .search-autocomplete {
      background: $white;
      border-radius: $global-padding;
      box-shadow: 0 15px 40px $grey-shadow-transparent;
      display: none; // initial state
      padding: $global-padding;
      position: relative;
      top: 1.9rem;
      width: 300px !important; // important to override the inline styles that get applied

      .autocomplete-list {
        border-bottom: 1px dashed $grey-light;

        dd {
          border-bottom: 1px dashed $grey-light;
          padding: $global-padding 0;

          .category-mini-crumb,
          .qs-option-name,
          .amount {
            font-size: rem-calc(14);

            &:hover {
              cursor: pointer;
            }
          }

          .amount {
            color: $grey;
            float: right;
            text-align: right;
          }

          &:hover {

            .product-item {
              color: $primary-color;
            }
          }
        }
      }

      .product-image-box {
        display: inline-block;
        vertical-align: top;
        width: 25%;

        &:hover {
          cursor: pointer;
        }
      }

      .product-item {
        display: inline-block;
        width: 72.5%;
        transition: color 300ms ease;

        &:hover {
          cursor: pointer;
        }
      }

      .product-primary {
        font-size: rem-calc(14);
        line-height: 1.2;
      }

      .product-secondary {
        font-size: rem-calc(12);
        font-weight: bold;
      }
    }
  }
}
