
.block.shipping {

  .fieldset {
    padding: 0;
  }

  .field.note {
    padding: 0 ($global-padding * 1.5);
  }

  .estimate {

    .field.note {
      display: none; // butt-hole
    }
  }

  .field[type="radio"] {
    display: inline-block;
    width: $global-padding;
  }

  .label {
    display: inline-block;
    width: 75%;
    vertical-align: top;
  }

  label {

    .label {
      font-weight: 400;
    }
  }
}
