
.block.free-shipping {
  @include grid-row;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  text-align: center;
  padding: $global-padding 0;
  border-bottom: 1px solid $grey-light;

  > * {
    width: 50%;

    @include breakpoint(medium) {
      display: inline-block;
      padding: 0 ($global-padding / 2);
      margin-bottom: 0;
      width: auto;
    }
  }

  a {
    background-color: $yellow;
    padding: ($global-padding / 2) ($global-padding * 0.75);
    margin-right: $global-margin / 2;
    border-radius: $global-radius;
    position: relative;

    @include breakpoint(medium) {

      &:before {
        content: '';
        position: absolute;
        left: $global-padding * -2.5;
        top: 0;
        height: 100%;
      }
    }
  }
}
