//Product toolbar for search and advanced search results
.toolbar-products {
  clear: both;
  padding-top: $global-padding;

  .pages,
  .limiter {
    display: none;
  }
}

.toolbar {
  @include grid-row;
  position: relative;

  // Display mode options
  .modes {
    display: none;
    margin-right: $global-margin;

    @include breakpoint(medium) {
      display: inline-block;
    }

    .modes-label {
      @include visually-hidden;
    }

    .modes-mode {
      background-color: $grey-light;
      box-shadow: inset 0 1px 0 0 $white, inset 0 -1px 0 0 rgba($grey, 0.3);
      color: $grey-dark;
      border: 1px solid $grey;
      border-right: 0;
      float: left;
      font-weight: 400;
      line-height: 1;
      padding: ($global-padding / 2) ($global-padding * 0.6);
      text-align: center;
      display: inline-block;
      text-decoration: none;
      border-radius: 3px 0 0 3px;

      &:last-child {
        border-radius: 0 3px 3px 0;
        border-right: 1px solid $grey;
      }

      span {
        @include visually-hidden;
      }

      &.active {
        box-shadow: inset 0 1px 0 0 rgba($grey, 0.8), inset 0 -1px 0 0 rgba($grey, 0.3);
        background: $grey-light;
        color: $grey;
      }
    }

    .mode-grid {
      @include icon($position: before, $icon: grid, $icon-set: 'fontawesome') {
        font-size: $global-font-size * 1.3;
      };
    }

    .mode-list {
      @include icon($position: before, $icon: list, $icon-set: 'fontawesome') {
        font-size: $global-font-size * 1.3;
      };
    }
  }

  // Item count display 
  .toolbar-amount {
    display: inline-block;
    position: absolute;
    top: $global-padding * -1.5;

    @include breakpoint(medium) {
      top: $global-padding * 1.5;
    }
  }

  // Number of items per page
  .field.limiter {
    float: right;

    > * {
      display: inline-block;
    }
  }

  // Sorting options
  .toolbar-sorter.sorter  {
    float: right;
    display: table;

    @include breakpoint(small only) {
      float: none;
      width: 100%;
    }

    > * {
      display: table-cell;
    }

    .sorter-label {
      padding-right: $global-padding;
    }

    .sorter-action {
      padding-left: $global-padding;

      &.sort-asc,
      &.sort-desc {

        span {
          font-size: 0;
          width: 0;
        }
      }

      &.sort-asc {
        @include icon($position: before, $icon: arrow-down, $icon-set: 'fontawesome') {
          font-size: $global-font-size * 1.5;
        };
      }

      &.sort-desc {
        @include icon($position: before, $icon: arrow-up, $icon-set: 'fontawesome') {
          font-size: $global-font-size * 1.5;
        };
      }
    }
  }

  // Pagination
  .pages {
    float: left;
    margin-bottom: 0;

    > .label {
      @include visually-hidden;
    }

    .items {
      font-size: 0;
      letter-spacing: -1px;
      line-height: 0;
      white-space: nowrap;
      margin: 0;
      padding: 0;
      list-style: none none;
      display: inline-block;
      font-weight: 400;
    }

    .item {
      font-size: $global-padding;
      letter-spacing: normal;
      line-height: 2;
      padding: 0 ($global-margin / 2);
      display: inline-block;
      transition: background-color 500ms ease, color 500ms ease;

      a {
        display: block;
      }

      .label {
        @include visually-hidden;
      }

      &.current {
        background-color: $primary-color;
        color: $white;
      }

      &:hover {
        background-color: rgba($primary-color, 0.9);
        color: $white;
        cursor: pointer;

        a {
          color: $white;
        }
      }
    }
  }

  &:after {
    clear: both;
  }
}

// Hides the display modes, amounts and sort options and displays the pager and limiter in the footer toolbar 
.products.wrapper {

  ~ .toolbar {

    .modes,
    .toolbar-amount,
    .toolbar-sorter {
      display: none;
    }

    .limiter {

      @include breakpoint(large) {
        display: block;
      }
    }

    .pages {
      display: block;

      @include breakpoint(medium down) {
        width: 100%;
        text-align: center;
      }
    }
  }
}
