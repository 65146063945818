
.block.block-compare {
  @include content-margin-gap-bottom;

  .block-title {
    font-size: $global-font-size * 1.4;

    strong {
      font-weight: 300;
    }

    .counter.qty {
      font-size: $global-font-size * 0.75;
      color: $grey;
      margin-left: $global-margin;
    }
  }

  ol {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin-bottom: $global-margin / 2;
      position: relative;
      padding-left: $global-padding * 1.25;

      .delete {
        @include icon($position: before, $icon-set: fontawesome, $icon: close);
        position: absolute;
        left: 0;
        top: 0;

        > span {
          @include visually-hidden;
        }
      }
    }
  }

  .action {

    &.clear {
      @include button($alt: true);
    }
  }
}
