.page-main {
  margin-top: $global-margin * 2;

  // Homepage main content removes margin-top
  .cms-index-index & {
    margin-top: 0;
  }
}

a + a {
  border-left: 0;
  margin-left: inherit;
  padding-left: inherit;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .page-wrapper .sections.nav-sections {
    width: 92%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 776px) {
  .page-wrapper {
    .page-header > .header.content .block.header-promo {
      display: inline-block;
      float: right;
      top: 1rem;
      position: absolute;
      z-index: 10;
      right: 75px;
    }
    .minicart-wrapper {
      top: 1rem;
      position: absolute;

      @media only screen and (max-width: 775px) and (min-width: 768px) {
        top: 0.75rem;
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .customer-account-create .page-main .create.account:not(.form) {
    min-width: auto;
  }
}

.sales-order-print {
  .table-order-items {
    caption {
      font-size: 0;
      visibility: hidden;
    }
  }
  .logo {
    background: #00519b;
    margin: 0 0 1rem 0;
    position: relative;
    @media only screen and (max-width: 775px) {
      padding: 1.25rem;
    }
    > img {
      width: 4.6875rem;
      padding: 1.25rem 0;
      @media only screen and (max-width: 775px) {
        display: none;
      }
    }
    @media only screen and (max-width: 775px) {
      &:after {
        content: '';
        background-image: url(../images/mobilelogo.svg);
        background-repeat: no-repeat;
        background-position: 50%;
        width: 100%;
        height: 2.0625rem;
        position: relative;
        top: 0;
        left: 0;
        display: block;
      }
    }
  }
  .nav-toggle {
    display: none;
  }
}
