
.block.widget.block-products-list {
  @include grid-row;
  margin-top: 2rem;
  margin-bottom: $global-margin;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;


  > * {
    @include grid-column(12);
  }

  > .block-title {
    text-align: center;
    font-size: rem-calc(32);
    margin-bottom: $global-margin;
    color: $blue-dark;
    margin-top: 2rem;

    strong {
      font-weight: $font-weight-medium;
    }
  }

  .products-grid.grid {
    margin-bottom: 0;
    .product-item{
      min-height: 22rem;
    }
  }
}
body.no-search-result-product {
  .message.notice{
    text-align: center;
  }
  .page-wrapper .page-main > .main{
    width: 100%;
    left: 0;
  }
}
@media screen and (max-width: 775px) and (min-width: 768px){
  .message.notice{
    margin-bottom: 25px;
  }
}
.cms-home {
	.block.widget.block-products-list {
		margin-top: 0;
		border-bottom: none;
		border-top: none;
	}
	.products-grid.grid {
		border-bottom: 1px solid lightgray;
	}
	.block.brand-callouts{
		margin-bottom: 0;
	}
}
@media screen and (max-width: 767px) {
	.block.widget.block-products-list .products-grid.grid .product-item {
		display:inline-block;
		float:none;
		vertical-align: top;
	}
}