
// Autocomplete search reasults
.searchautocomplete__autocomplete {
  border: 0;
  background-color: $white;
  box-shadow: 0 0 13px rgba($black, 0.15);
  border-radius: $global-radius / 3;
  color: $grey-dark;
  padding: ($global-padding * 2) $global-padding;
  width: $global-padding * 19 !important;
  display: none !important;
}

// Search results page
.catalogsearch-result-index {

  .search__result-tabs {
    display: none;
  }
}

.mst-searchautocomplete__close {
  display: none;
}
