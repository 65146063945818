//Breadcrumbs
.breadcrumbs {
  @include grid-row();
  max-width: $grid-row-narrow;
  padding: $global-padding 0;

  ul {
    list-style: none;

    li {
      font-size: $global-font-size;
      text-transform: none;
      display: inline-block;
      padding: 0 ($global-padding / 2);
    }

    li:not(:first-child) {
      @include icon($position: before, $icon: more, $icon-set: fontawesome, $styles: true) {
        margin-left: $global-margin * -1;
        color: $grey;
        padding: 0 ($global-padding / 2);
      };
    }
  }
}
