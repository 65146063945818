//Site Footer
footer {
  @include content-padding-gap;
  position: relative;

  > .footer.content {
    @include grid-row;
    padding: 0;

    .block.footer-social {
      @include grid-column(12);

      @include breakpoint(medium) {
        @include grid-column(4);
      }
    }

    .footer-menu {
      @include grid-column(12);

      @include breakpoint(medium) {
        @include grid-column(8);
      }
    }
  }

  > .block.footer-bottom {
    @include grid-row;

    > * {
      @include grid-column(12);

      @include breakpoint(medium) {
        @include grid-column(6);
      }
    }
  }

  &.page-footer {
    margin-top: 0;
    color: $white;
    background-color: $primary-color;
  }

  // modal footers should inherit the background color of the modal itself, usually white.
  &.modal-footer {
    background: transparent;
    padding-bottom: $global-padding * 2;
  }
}
