
.block.filter {

  .block-title {
    @extend %button;
    @include button($outline: true);
    position: absolute;
    top: 0;
    right: $global-padding;

    @include breakpoint(medium) {
      display: none;
    }
  }

  .block-content {

    @include breakpoint(small only) {
      display: none;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: $white;
      z-index: 99;
      overflow: scroll;

      &.active {
        display: block;
      }
    }
  }

  .block-subtitle {
    display: block;
    padding-bottom: $global-padding;
    padding-top: $global-padding;
    font-size: $global-font-size * 1.2;
    position: relative;

    @include breakpoint(small only) {
      @include icon($position: after, $icon-set: fontawesome, $icon: close) {
        position: absolute;
        right: $global-padding;
        top: $global-padding;
        color: $white;
      };
      cursor: pointer;
      padding-left: $global-padding / 2;
      background-color: $primary-color;
      color: $white;
    }
  }

  // Now Shopping By
  .filter-current {

    @include breakpoint(small only) {
      display: none;
    }
    
    .block-subtitle {
      margin-top: 0;
    }

    .item {
      position: relative;

      .filter-label {
        font-weight: bold;
        padding-left: $global-padding * 1.25;

        &::after {
          content: ':';
        }
      }

      .action.remove {
        @include icon($position: before, $icon-set: fontawesome, $icon: close);
        position: absolute;
        left: 0;
        right: 0; // left and right 0 make this action full width of the name - could be updated so only the icon is the remove link
        top: 0;

        span {
          @include visually-hidden;
        }
      }
    }

    & + .filter-actions {

      @include breakpoint(small only) {
        display: none;
      }
    }
  }

  .filter-options-title {
    @include icon($position: after, $icon-set: fontawesome, $icon: more) {
      position: absolute;
      right: $global-padding;
      top: $global-padding / 4;
      font-size: $global-font-size * 1.5;
    };
    position: relative;
    cursor: pointer;
    font-weight: 600;
    margin: 0;
    overflow: hidden;
    padding: ($global-padding * 0.75) ($global-padding);
    text-transform: uppercase;
    word-break: break-all;
    z-index: 1;
    display: block;
    text-decoration: none;
    border-top: 1px solid $grey-light;

    @include breakpoint(medium) {
      padding: ($global-padding * 0.75) ($global-padding * 2) ($global-padding * 0.75) ($global-padding / 4);
    }

    + .filter-options-content {
      display: none;
    }

    &.open {
      @include icon($position: after, $icon-set: fontawesome, $icon: less);

      + .filter-options-content {
        display: block;
      }
    }
  }

  .filter-options-content {
    margin: 0;
    padding: 0 $global-padding ($global-padding / 4);

    @include breakpoint(medium) {
      padding: ($global-padding / 4) ($global-padding / 4) $global-padding;
    }
  }

  .items {
    list-style: none;
    margin: 0;
    padding: 0;

    .item {
      line-height: 1.5;
      margin: ($global-margin / 2) 0;

      > a {
        display: block;
        transition: color 400ms ease;
      }

      &:hover {

        > a {
          color: $primary-color;
        }
      }

      .count {
        color: $grey;

        &::before {
          content: '(';
        }

        &::after {
          content: ')';
        }
      }

      .filter-count-label {
        @include visually-hidden;
      }
    }
  }
}
