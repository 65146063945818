.block.myaccount {
  list-style: none;
  text-align: center;

  @include breakpoint(medium only) {
    position: static;
    top: $global-padding * 0.75;
    left: $global-padding * 2;
  }

  @media only screen and (max-width: 775px) and (min-width: 768px) {
    position: absolute;
    top: 0.75rem;
    left: 2rem;
    font-size: 0;
  }

  @include breakpoint(small down) {
    position: absolute;
    top: $global-padding * 0.75;
    left: $global-padding * 2;
    font-size: 0;
  }

  li {
    a {
      color: $white;
      font-weight: normal;
      margin-top: $global-margin / 3;
      display: block;

      &::before {
        content: '';
        color: $white;
        display: block;
        filter: drop-shadow(0 63px 122px rgba($blue-dark, 0.8));
      }
    }

    &:hover {
      a {
        color: $yellow;
      }
    }
  }
}
