
.block.header-promo {

  @include breakpoint(small only) {
    position: absolute;
    z-index: 5;
    top: $global-padding;
    right: $global-padding * 4;
  }

  p {
    display: none;

    @include breakpoint(medium) {
      display: block;
      color: $white;
      margin-bottom: 0;
    }

    &.phone {
      display: block;

      @include breakpoint(medium down) {
        font-size: 0;
      }
    }
  }

  a {
    color: $white;
    transition: color 400ms ease;

    &:hover {
      color: $yellow;
    }

    &::before {
      content: "\f095";
      color: $white;
      font-size: 22px;
      line-height: 33px;
      font-family: fontawesome;
      width: 35px;
      height: 35px;
      border-radius: 35px;
      display: inline-block;
      filter: drop-shadow( 0 63px 122px rgba($blue-dark, 0.8));
      border:2px solid #fff;
      background: #51abff;
      text-indent: 7px;
      @include breakpoint(medium) {
        display: none;
      }
      @media only screen and (max-width: 320px) and (min-width: 0) {
        font-size: 15px;
        height: 30px;
        width: 30px;
      }

      @media only screen and (min-width: 776px) and (max-width: 1152px) {
        height: 50px;
        width: 50px;
        line-height: 46px;
        font-size: 35px;
      }
    }
  }
}
