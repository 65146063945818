// Product view page
.catalog-product-view {
  .page-main {
    background-color: $white;
    padding-bottom: $global-padding * 2;

    // this is causing all sorts of floating issues.
    .columns {
      float: none;
    }

    .product-info-main {
      @include grid-column(12);

      @include breakpoint(medium) {
        @include grid-column(6);
        // @include grid-column-position(6);
      }

      .page-title-wrapper.product {
        padding: 0;

        h1 {
          font-size: rem-calc(34);
          margin-bottom: $global-margin * 0.75;
        }
      }
    }

    .product.info.detailed {
      @include grid-column(12);
    }

    .attribute.overview {
      font-size: $global-padding;
      margin-bottom: $global-margin;
    }

    .product-info-price {
      display: flex;
      flex-direction: column;
      padding-bottom: $global-padding;
      border-bottom: 1px solid rgba($grey-dark, 0.5);

      @include breakpoint(medium) {
        flex-direction: row;
        justify-content: space-between;
        align-items: end;
      }

      .price-box {
        .price {
          font-weight: $font-weight-bold;
          font-size: rem-calc(24) !important;

          // &:before {
          //   content: 'From ';
          //   font-weight: $font-weight-book;
          //   font-size: rem-calc(18);
          // }
        }
      }

      .product-info-stock-sku {
        @include breakpoint(medium) {
          text-align: right;
          margin-top: $global-margin * 0.75;
        }

        .stock {
          font-weight: $font-weight-medium;

          &.available {
            color: map-get($foundation-palette, success);
          }

          &:not(.available) {
            color: map-get($foundation-palette, alert);
          }
        }

        .sku {
          strong {
            font-weight: $font-weight-book;
          }

          > * {
            display: inline;
          }

          .type {
            &::after {
              content: ':';
            }
          }
        }
      }
    }

    .product.alert.stock {
      margin-top: $global-margin * 1.5;
    }

    .product-reviews-summary {
      margin-bottom: $global-margin;

      > div {
        display: inline;

        a + a {
          float: none;
          display: block;
          border-left: none;
          margin-top: $global-margin / -2;
          margin-left: 0;
          padding-left: 0;

          @include breakpoint(medium) {
            float: right;
            margin-top: 0;
          }
        }
      }

      .rating-summary {
        margin-right: $global-margin / 2;
      }
    }

    .product-add-form {
      padding-top: $global-padding;

      .product-options-wrapper {
        margin-bottom: $global-margin;
        padding-right: $global-padding;
        display: block;
      }

      .fieldset {
        @include grid-row($behavior: nest);
        border: 0;
        padding: 0;
        margin: 0;

        .field.configurable,
        .field.configurable + .field.configurable,
        .field.required {
          @include grid-column(12);

          @include breakpoint(medium) {
            @include grid-column(6);
          }
        }

        div.mage-error {
          margin-top: $global-margin * -1;
        }

        label {
          color: $body-font-color;
          font-weight: $font-weight-bold;
          float: left;
          margin-right: $global-margin;
          padding-top: $global-padding / 2;
        }

        input {
          float: left;
          width: 20%;
          text-align: center;
        }
      }

      .product-options-bottom {
        display: block;

        .fieldset {
          border: 0;
          padding: 0;
          margin: 0;

          input {
            width: 40%;
            height: $global-padding * 3;
            border-radius: $global-radius;
          }

          > div {
            float: left;
            width: 100%;

            @include breakpoint(medium) {
              width: 50%;
            }
          }
        }

        .field.qty {
          max-width: 100%;
          margin-top: $global-margin / 3;

          @include breakpoint(medium) {
            width: 30% !important;
          }
        }

        .actions {
          clear: none;
          margin-top: $global-margin / 3;
          text-align: center;

          @include breakpoint(medium) {
            text-align: left;
          }
        }
      }
    }

    .product.media {
      @include grid-column(12);
      position: relative;

      @include breakpoint(medium) {
        @include grid-column(6);
      }
    }

    .product-details {
      .product.attribute.description {
        margin-bottom: $global-margin * 0.75;

        > .type {
          font-size: $global-font-size * 1.5;
          color: $body-font-color;
          font-weight: $font-weight-medium;
          display: block;
        }
      }

      .block-title {
        color: $body-font-color;
        font-size: $global-font-size * 1.5;
        font-weight: $font-weight-medium;
        margin-bottom: $global-margin * 2;

        strong {
          font-weight: $font-weight-medium;
        }
      }

      h2 {
        @include header-size(h3);
        margin-bottom: $global-margin * 3;
      }
    }
  }

  &:not(.page-product-configurable) {
    .page-main {
      .product-add-form {
        .fieldset {
          @include grid-row();
          border: 0;
          padding: 0;
          margin: 0;

          > div {
            @include grid-column(6);
            padding-left: 0 !important;
            float: left !important;

            &.field.qty {
              margin-top: $global-margin / 2;
            }

            &:only-of-type {
              float: left !important;
            }
          }

          input {
            float: left;
            height: $global-padding * 3;
            border-radius: $global-radius;
            width: 30%;
            text-align: center;
          }
        }
      }
    }
  }
}
