// Animated Form styles - currently on the Medicaid page.
.form-animate {

  // Successful form submission - flip the form over to display thank you message.
  &.success {

    .form-content {
      transform: rotateY(180deg);
    }

    .confirmation {
      transform: rotateY(0deg);
      z-index: 10;
    }
  }

  .animate-inner {
    position: relative;
    margin-bottom: $global-margin;
    height: $global-padding * 88;

    @include breakpoint(medium) {
      height: $global-padding * 65;
    }

    @include breakpoint(large) {
      height: $global-padding * 67;
    }
  }

  .form-content,
  .confirmation {
    transition: 0.6s;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .form-content {
    z-index: 2;
    transform: rotateY(0deg);
  }

  .confirmation {
    background-color: $secondary-color;
    padding: $global-padding * 2;
    text-align: center;
    transform: rotateY(-180deg);
    z-index: 1;

    h2 {
      color: $white;
    }

    .thankyou-message {

      p {
        color: $white;
      }
    }
  }

  // When the form is loading, add an opacity and loading spinner.
  .ajax-loading & {
    position: relative;

    &::before {
      content: '';
      background-image: url('../images/loading.gif');
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
      width: 100%;
      height: 100%;
      display: none;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
      height: 100%;
      width: 100%;
      background-color: rgba($white, 0.7);
      display: none;
    }
  }
}
