.block.review-list {
  @include grid-row;

  .block-title {
    @include header-size(h3);
    border-bottom: 1px solid $grey-light;
    margin-bottom: $global-margin * 3;
  }

  .toolbar.review-toolbar {
    display: none;
    float: right;
    max-width: 25%;
  }

  ol {
    margin: 0;
    list-style: none;

    li {
      @include grid-row;
      margin-top: $global-margin * 2;

      &:not(:last-of-type) {

        @include breakpoint(small only) {
          border-bottom: 1px solid $grey-light;
          padding-bottom: $global-padding * 2;
        }
      }
    }
  }

  .left {
    @include breakpoint(small only) {
      float: none;
      margin-bottom: $global-margin / 2;
    }

    @include grid-column(12);

    @include breakpoint(medium) {
      @include grid-column(3);
    }

    .rating-result,
    .review-author,
    .review-date {
      margin-bottom: 0;
    }

    .review-author,
    .review-date {
      font-size: $global-font-size * 0.9;
    }
  }

  .right {
    @include grid-column(12);

    @include breakpoint(medium) {
      @include grid-column(9);
    }

    .review-title {
      @include header-size(h4);
      font-weight: bold;
    }
  }
}

.block.review-add {
  margin-top: $global-padding * 3;

  .block-title {
    display: none;
  }

  .review-field-ratings {

    legend {
      display: none;
    }
  }

  .review-fieldset {
    border: 0;
    padding: 0;

    legend {
      text-align: left;
      margin-bottom: $global-margin * 5;

      span {
        font-size: $global-font-size;
        color: darken($grey, 15%);
      }

      strong {
        font-size: $global-font-size * 1.3;
      }
    }

    label {
      font-weight: bold;
      color: $primary-color;
    }

    br {
      display: none;
    }

    input[type=text],
    textarea {
      width: 100%;
      border-radius: $global-radius / 2;

      @include breakpoint(medium) {
        width: 50%;
      }
    }

    .review-field-ratings {
      margin-bottom: $global-margin;
    }
  }

  .actions-primary {
    float: left;

    button {
      @include button($outline: true, $reverse: false, $large: false);
    }
  }
}
