// Account creation page - overrides from HDIS Default.
.customer-account-create {

  .page-main {

    .create.info,
    .address,
    .create.account:not(.form) {

      @include breakpoint(medium) {
        min-height: auto;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .customer-account-create{
    .form-create-account .actions-toolbar .primary{
      margin-top: 8px;
    }
    .page-title-wrapper{
      padding: 0 18px;
    }
  }
}
@media only screen and (max-width: 1023px) and (min-width: 768px){
  .customer-account-create {
    .form-create-account .actions-toolbar .primary {
      margin-top: 8px;
    }
  }
}
