.form.medicaid-aob-form {

  .field.agree_to_terms {
    margin-bottom: 1rem;
    input[type="checkbox"] {
      display: inline-block;
      height: 24px;
      width: 24px;
      vertical-align: top;
    }
    label {
      display: inline-block;
      font-size: rem-calc(16);
      line-height: 1.2;
      width: 80%;
    }
  }
  .aob-terms-conditions-wrapper {
    font-size: rem-calc(12);
    li {
      margin-bottom: $global-padding * 0.5;
    }
  }
  .g-recaptcha {
    @include breakpoint(medium down) {
      width: rem-calc(100);
      transform: scale(0.7);
      margin-left: $global-margin * -1.75;
    }
  }
}
