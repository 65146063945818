
.product-reviews {

  .block.review-add {

    .actions-primary {

      button {
        @include button;
        box-shadow: none;
        border: none;
      }
    }
  }

  .review-control-vote {

    &::before {
      content: '\f006 \f006 \f006 \f006 \f006';
    }

    label {

      &::before {
        color: $yellow-dark;
      }
    }
  }

  .rating-result {
    font-size: $global-font-size * 2;
  }

  .block.review-list {

    .block-title {
      border-bottom: 0;
      margin-bottom: 0;
      padding-top: $global-padding * 3;
      border-top: 1px solid $grey-light;
    }

    .left {

      .review-author,
      .review-date {
        font-size: $global-font-size;
      }

      strong {
        font-weight: $font-weight-medium;
      }
    }

    .right {

      .review-title {
        font-weight: $font-weight-medium;
      }
    }
  }
}

// changing our default stars to outlined stars
.rating-result {
  @include icon($position: before, $icon: star-outline, $icon-set: fontawesome, $styles: true) {
    content: '\f006 \f006 \f006 \f006 \f006';
    color: transparent;
  };
  letter-spacing: 3px;

  > span {

    &::before {
      color: $yellow-dark;
    }
  }
}
