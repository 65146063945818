
.block.culture-athdis {
  @include grid-row;
  @include component-margin-bottom;
  max-width: $grid-row-narrow;

  .culture-column {
    @include grid-column(12);
    margin-bottom: $global-margin;

    @include breakpoint(medium) {
      @include grid-column(6);
    }
  }

  .block.culture-video {
    margin-bottom: $global-margin;

    iframe {
      max-width: 100%;
    }
  }
}
