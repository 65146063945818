
.block.related {

  .title {
    margin-bottom: $global-margin;
  }

  .item.product {

    .product-reviews-summary {
      margin-bottom: 0;
      justify-content: center;
      font-size: $global-font-size * 1.4;

      .rating-result {
        margin-bottom: 0;

        > span::before { // sass-lint:disable-line
          color: $yellow-dark;
        }
      }
    }
  }
}
