//Customer Account Orders View Page
.sales-order-view {

  .order-status {
    font-weight: bold;
    font-size: $global-font-size * 1.5;
    color: $primary-color;
  }

  .main {
    display: flex;
    flex-direction: column;

    > .actions-toolbar {
      order: 4;
      margin-top: $global-margin;
    }
  }
}

// Order history table
.orders-history {

  table {

    &.history.table-order-items {

      tbody {

        > tr {
          border-bottom: 1px solid $grey-light;

          &:nth-child(even) {
            background-color: $grey-light;
          }
        }
      }
    }
  }
}

// Halo order history
.halo-order-history-wrapper {
  margin-bottom: $global-margin;
  margin-top: $global-margin;

  > h2 {
    font-size: rem-calc(28);
    color: $secondary-color;
  }

  > .note {
    font-size: $global-font-size * 0.9;
  }

  // Halo order history table
  table.table.halo-history {

    // We are hiding the table header on mobile.
    thead {

      > tr {

        > th {
          @include breakpoint(small only) {
            display: none;
          }
        }
      }
    }

    > tbody {

      > tr {
        border-bottom: 1px solid $grey-light;

        // Order details that will toggle open and closed
        &.halo-order-details-row {
          display: table-row;

          &.hidden {
            display: none;

            > td {
              max-height: 0;
              visibility: hidden;
              opacity: 0;
              transition: max-height 400ms ease, visibility 400ms ease, opacity 400ms ease;
            }
          }

          > td {
            height: auto;
            max-height: 100%;
            visibility: visible;
            opacity: 1;
            transition: visibility 300ms ease, opacity 300ms ease, height 300ms ease;
          }
        }

        // On the main and nested tables we want to display the data-th attribute as the label for mobile.
        td {
          @include breakpoint(small only) {
            display: block;
            padding: ($global-padding / 2) ($global-padding);

            &::before {
              padding-right: $global-padding / 3;
              content: attr(data-th);
              display: inline-block;
              font-weight: bold;
            }
          }
        }

        // select every 4th row starting with row 1
        &:nth-child(4n+1) {
          background-color: $grey-light;
        }
      }

      // Order details view
      table.order-information-details {

        .totals {
          font-weight: bold;

          @include breakpoint(small only) {
            display: block;
            margin-left: $global-margin * -1;
            margin-right: $global-margin * -1;
          }

          td {
            vertical-align: middle;
            padding-left: $global-padding * 0.625;

            &[class*='order'] {

              @include breakpoint(medium) {
                text-align: right;
                padding-right: $global-padding * 1.5;
              }
            }
          }

          &:nth-child(odd) {
            background-color: $grey-light;
          }
        }

        tr {

          td {

            @include breakpoint(small only) {
              padding-left: 0;
              padding-right: 0;
            }
          }
        }
      }
    }
  }
}
