// Main top menu

.sections.nav-sections {
  background: $blue-light;
}

.block.ves-menu {
  @include grid-row;

  > nav {

    @include breakpoint(medium) {
      @include grid-column(12);
    }
  }

  ul.ves-megamenu {
    margin-left: 0;
    background-color: transparent;

    li {

      a {
        font-size: rem-calc(15);
        text-transform: capitalize;
        font-weight: $font-weight-bold;
      }

      // Main level navigation
      &.level0 {

        > a {
          color: $white;
          line-height: $global-padding * 3.25;
          transition: padding 300ms ease, margin 300ms ease;

          @include breakpoint(small only) {
            padding: 0 ($global-padding / 4);
          }
        }

        &:hover {

          > a {
            background: $white;
            color: $blue;

            @include breakpoint(medium) {
              padding-top: $global-padding / 2;
              margin-top: $global-margin / -2;
            }
          }
        }

        // Child menu under main navigation links
        .submenu {
          border: 0;
          width: 100%;
          border-radius: 0 0 5px 5px;

          @include breakpoint(medium down) {
            top: $global-padding * 3 !important;
            padding: $global-padding / 2;
          }

          a {
            color: $blue-light;
            padding: ($global-padding / 2) ($global-padding * 0.75);
            transition: color 300ms ease, background-color 300ms ease;

            &:hover {
              color: $blue;
              background-color: transparent;
            }
          }
        }

        &.specials {

          @include breakpoint(medium) {

            > a,
            > a:hover {
              position: relative;

              &:before {
                content: '';
                background-image: url('../images/specials-splash.svg');
                background-position: center;
                background-repeat: no-repeat;
                position: absolute;
                left: 0;
                right: 0;
                top: $global-padding * -0.8;
                width: 100%;
                height: 100%;
              }
            }

            > a:hover {

              &:before {
                content: '';
                background-image: url('../images/specials-splash-hover.svg');
                top: $global-padding * -0.5;
              }
            }
          }
        }
      }
    }
  }
}

