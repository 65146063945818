// CMS Careers Page
.cms-page-view.cms-careers,
.careers-index-index {

  // Admin note about content -
  // the content for this is managed via XML and CMS Blocks
  .hidden.note {
    display: none;
  }
}
