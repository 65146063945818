
.footer-menu {

  > ul {
    list-style: none;
    margin-left: 0;
    margin-bottom: $global-margin;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include breakpoint(medium) {
      flex-direction: row;
    }

    > li {
      display: block;
      margin-bottom: $global-margin / 2;

      a {
        color: $white;
        line-height: 2;
        font-weight: $font-weight-book;
        display: block;
        transition: color 400ms ease;

        &.no-link {
          font-size: rem-calc(18);
          font-weight: 800;
          pointer-events: none;

          &:hover {
            color: $white;
          }
        }

        &:hover {
          color: $yellow;
        }
      }

      ul {
        margin-left: 0;
        list-style: none;
      }
    }
  }
}
