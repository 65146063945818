
.block.open-positions {

  h2 {
    color: $blue-dark;
  }

  .careers-open-positions {
    tr:nth-child(odd)   {
      background-color: $grey-lighter;
    }
    tr:nth-child(even) {
      background-color: $white;
    }
  }
}
