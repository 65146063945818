.customer-subscriptions-view {

  .page-title-wrapper {
    background-color: transparent;
    margin-bottom: 2rem;
    padding: 1rem 1.5rem;

    .page-title {
      background-color: transparent;
      display: inline-block;
      margin-bottom: 0;
      padding: 0 0 0 1.5rem;
    }

    .subscription-status {
      background: $secondary-color;
      border: 1px solid $secondary-color;
      color: $white;
      margin-left: 2rem;
      padding: .2rem .5rem;
    }
  }

  .subscription.table {

    .table-caption {
      font-size: 1.2rem;
      font-weight: 400;
    }

    td:not(.subscription-product) {
      padding-top: $global-padding * 1;
    }
  }

  .subscription-product {
    padding-top: 0;

    .product-image {
      float: left;
      width: 20%;
      padding-top: $global-padding * 1;
    }

    .subscription-details {
      left: 0;
      position: relative;
      top: 1rem;

      .product-name {
        font-size: 1.2rem;
      }

      .subscription-frequency {
        display: block;
        font-size: .875rem;
        margin-top: $global-padding * 1;
      }
    }
  }

  .subscription-price {

    .price {
      font-weight: 600;
    }

    .savings {
      display: block;
      font-size: .875rem;
      font-style: italic;
    }
  }

  .block-subscription-info {
    float: left;
    margin-bottom: $global-padding * 1;
    width: 100%;

    .block-title {
      // border-bottom: 1px solid $grey-lighter;
      font-size: 1.2rem;
      // padding-bottom: .5rem;

      strong {
        font-weight: 400;
      }
    }

    .block-content {
      background: $white;
      float: left;
      margin-top: $global-padding * 1;
      width: 100%;

      .box {
        float: left;
        margin: 0 .875rem;
        min-height: 250px;
        padding: $global-padding * 1 $global-padding / 2;
        width: 29%;
      }
    }

    .item-options {
      dt {
        display: block;
        float: none;
        width: 100%;
      }

      dd {
        margin-bottom: $global-padding / 2;
      }
    }
  }

}
